import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useApi } from '../../../hooks/useApi';
import Loader from '../../Loader';
import './Timeline.scss';
import { SUBSCRIPTION_CODES } from '../../../utility/Constants';

export type TimelineProps = {
  id: string;
  numberOfItemsToShow: number;
  createdDate: string;
  deletedDate: string;
  trialEndDate: string;
};

export function Timeline(props: TimelineProps) {
  const { id, numberOfItemsToShow, createdDate, deletedDate } = props;
  const [changeLog, changeLogLoading, changeLogLoaded] = useApi<TypeMePlease>(`/api/account/${id}/changelog?limit=${numberOfItemsToShow || 10}`);
  const [proServiceLog, proServiceLogLoading, proServiceLogLoaded] = useApi<TypeMePlease>(`/api/account/${id}/proservicelogs`);
  const [publicFormCreditLog, publicFormCreditLogLoading, publicFormCreditLogLoaded] = useApi<TypeMePlease>(`/api/account/${id}/publicformcredits`);
  const [eventLog, eventLogLoading, eventLogLoaded] = useApi<TypeMePlease>(`/api/account/${id}/eventlog`);

  if (!changeLogLoaded || !proServiceLogLoaded || !publicFormCreditLogLoaded || !eventLogLoaded) {
    return (
      <div className="timeline">
        <Loader isLoading />
      </div>
    );
  }

  function addComma(activities) {
    if (activities.length > 0) {
      activities.push(', ');
    }

    return activities;
  }

  function getChangeLogTimelineItems(acv, activities, timestamp, offset = 0) {
    return {
      element: (
        <div key={i} className={`timeline-item ${acv > 0 ? 'positive' : acv < 0 ? 'negative' : 'neutral'}`}>
          <i className={`ui icon ${acv > 0 ? 'add' : acv < 0 ? 'minus' : 'outline'} circle`} />
          <div className="item-body">
            <div className="activities">{activities}</div>
            <div className="timestamp">{moment.utc(timestamp).local().format('MM/DD/YYYY h:mm a')}</div>
          </div>
        </div>
      ),
      timestamp: moment.utc(timestamp).add(offset, 'millisecond'),
    };
  }

  function getEventLogTimelineItems(tooltip, netACV, events, userName, createdDate) {
    return {
      element: (
        <Popup
          key={`${i}-${e}`}
          content={tooltip}
          disabled={!tooltip}
          position="left center"
          trigger={
            <div key={e} className={`timeline-item ${netACV > 0 ? 'positive' : netACV < 0 ? 'negative' : 'neutral'}`}>
              <i className={`ui icon ${netACV > 0 ? 'add' : netACV < 0 ? 'minus' : 'outline'} circle`} />
              <div className="item-body">
                <div className="activities">{events}</div>
                <div className="timestamp">
                  {moment.utc(createdDate).local().format('MM/DD/YYYY h:mm a')}
                  {userName ? ` - ${userName}` : ''}
                </div>
              </div>
            </div>
          }
        />
      ),
      timestamp: moment.utc(createdDate),
    };
  }

  function GetEditionFromEditionCode(edition: string) {
    switch (edition) {
      case SUBSCRIPTION_CODES.BASIC:
        return 'Basic';
      case SUBSCRIPTION_CODES.TRIAL:
        return 'Trial';
      case SUBSCRIPTION_CODES.TEACHER:
        return 'Teacher';
      case SUBSCRIPTION_CODES.TEAM:
        return 'Team';
      case SUBSCRIPTION_CODES.ADVANCED:
        return 'Advanced';
      case SUBSCRIPTION_CODES.ENTERPRISE:
        return 'Enterprise';
      default:
        return 'Unknown';
    }
  }

  const timeline = [];

  let i = 0;
  for (const item of changeLog) {
    const activities = [];
    const addOnActivities = [];

    if (item.oldAddOnSubscriptionCodes !== item.newAddOnSubscriptionCodes) {
      const oldAddOnCodes = item.oldAddOnSubscriptionCodes?.trim().split(',');
      const newAddOnCodes = item.newAddOnSubscriptionCodes?.trim().split(',');

      const addedAddOns = newAddOnCodes?.filter((newCode) => newCode?.trim() !== '' && !oldAddOnCodes?.includes(newCode.trim()));
      const addedAddOnText = addedAddOns?.length > 0 ? `Add-ons added: ${addedAddOns.join(', ')}` : null;

      const removedAddOns = oldAddOnCodes?.filter((oldCode) => oldCode?.trim() !== '' && !newAddOnCodes?.includes(oldCode.trim()));
      const removedAddOnText = removedAddOns?.length > 0 ? `Add-ons removed: ${removedAddOns.join(', ')}` : null;

      if (addedAddOnText) {
        addComma(addOnActivities);
        addOnActivities.push(<span key={`added-addOns-${item.id}`}>{addedAddOnText}</span>);
      }

      if (removedAddOnText) {
        addComma(addOnActivities);
        addOnActivities.push(<span key={`removed-addOns-${item.id}`}>{removedAddOnText}</span>);
      }
    }

    if (item.netAddOnACV) {
      addComma(addOnActivities);
      addOnActivities.push(
        <span key={`add-on-acv-${i}`}>Net add-on ACV {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netAddOnACV)}</span>
      );
    }

    if (item.licenseCount === null) {
      activities.push(<span key="licenseCount">Updated licensing to non user pricing</span>);
    }

    if (item.licenseCount !== null && item.licenseCount !== item.oldLicenseCount) {
      const licenseDifference = Math.abs(item.licenseCount - item.oldLicenseCount);
      activities.push(
        <span key="licenseCount">
          {item.licenseCount > item.oldLicenseCount ? 'Added' : 'Removed'} {licenseDifference} license{licenseDifference > 1 ? 's' : ''} for a total of{' '}
          {item.licenseCount}
        </span>
      );
    }
    if (item.price !== null && item.price !== item.oldPrice) {
      if (activities.length > 0) {
        activities.push(', ');
      }
      activities.push(
        <span key="price">
          Price changed from ${item.oldPrice} to ${item.price}
        </span>
      );
    }
    if (item.netACV) {
      if (activities.length > 0) {
        activities.push(', ');
      }
      activities.push(<span key="acv">Net ACV {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netACV)}</span>);
    }

    if (activities.length > 0) {
      timeline.push(getChangeLogTimelineItems(item.netACV, activities, item.timestamp));
    }

    if (addOnActivities.length > 0) {
      const netAddOnACV = (item.newAddOnACV ?? 0) - (item.oldAddOnACV ?? 0);
      //add-on activities should appear after the license count changes since both events can occur simultaneously, and license count changes affects add-on ACV
      //to achieve this, add a 1 millisecond offset to the add-on activities, ensuring they display after the license changes
      timeline.push(getChangeLogTimelineItems(netAddOnACV, addOnActivities, item.timestamp, 1));
    }
    i++;
  }

  timeline.push({
    element: (
      <div key="created" className="timeline-item neutral">
        <i className={`ui icon outline circle`} />
        <div className="item-body">
          <div className="activities">Account created</div>
          <div className="timestamp">{moment.utc(createdDate).local().format('MM/DD/YYYY h:mm a')}</div>
        </div>
      </div>
    ),
    timestamp: moment.utc(createdDate),
  });

  if (proServiceLogLoaded) {
    for (const log of proServiceLog) {
      timeline.push({
        element: (
          <div key={log.id} className="timeline-item positive">
            <i className={`ui icon outline circle`} />
            <div className="item-body">
              <div className="activities">
                {log.name} ${log.amount}
              </div>
              <div className="timestamp">{moment.utc(log.closeDate).local().format('MM/DD/YYYY h:mm a')}</div>
            </div>
          </div>
        ),
        timestamp: moment.utc(log.closeDate),
      });
    }
  }

  if (publicFormCreditLogLoaded) {
    for (let publicFormCreditLogIndex = 0; publicFormCreditLogIndex < publicFormCreditLog.length; publicFormCreditLogIndex++) {
      const log = publicFormCreditLog[publicFormCreditLogIndex];
      timeline.push({
        element: (
          <div key={`pf${publicFormCreditLogIndex}`} className="timeline-item positive">
            <i className={`ui icon outline circle`} />
            <div className="item-body">
              <div className="activities">
                Added {log.newCreditCount - log.oldCreditCount} public form credits for ${log.price}
              </div>
              <div className="timestamp">{moment.utc(log.createdDate).local().format('MM/DD/YYYY h:mm a')}</div>
            </div>
          </div>
        ),
        timestamp: moment.utc(log.createdDate),
      });
    }
  }

  const PENDING_REMOVE_LICENSES = 'PENDING_REMOVE_LICENSES',
    CANCEL_REMOVE_LICENSES = 'CANCEL_REMOVE_LICENSES',
    PENDING_EDITION_DOWNGRADE = 'PENDING_EDITION_DOWNGRADE',
    CANCEL_EDITION_DOWNGRADE = 'CANCEL_EDITION_DOWNGRADE',
    EDITION_UPGRADE = 'EDITION_UPGRADE',
    EDITION_DOWNGRADE = 'EDITION_DOWNGRADE',
    PENDING_ADDON_REMOVAL = 'PENDING_ADDON_REMOVAL',
    CANCEL_ADDON_REMOVAL = 'CANCEL_ADDON_REMOVAL';

  let e = 0;
  for (const item of eventLog) {
    const events = [];
    const addOnEvents = [];

    const recurlyStatus =
      item.recurlyNotified && item.username == '' ? 'Recurly Notified: ' : item.recurlyNotified ? 'User Clicked - Recurly Notified: ' : 'User Clicked: ';

    const tooltip =
      item.recurlyNotified && item.username == '' && (item.action === PENDING_REMOVE_LICENSES || item.action === PENDING_EDITION_DOWNGRADE)
        ? 'This is when we tell Recurly to process the downgrade, factoring in the 30 day rule'
        : item.recurlyNotified && (item.action === PENDING_REMOVE_LICENSES || item.action === PENDING_EDITION_DOWNGRADE)
          ? "This is the date and user who clicked to downgrade, and because it's within 30 days, we also tell Recurly at the same time"
          : item.action === PENDING_REMOVE_LICENSES || item.action === PENDING_EDITION_DOWNGRADE || item.action === PENDING_ADDON_REMOVAL
            ? 'This is the date and user who clicked to downgrade'
            : item.action === EDITION_DOWNGRADE
              ? 'This is when the downgrade is complete'
              : null;

    if (item.action === PENDING_REMOVE_LICENSES || item.action === CANCEL_REMOVE_LICENSES) {
      const licenseDifference = Math.abs(item.newLicenses - item.oldLicenses);
      events.push(
        <span key="licenseCount">
          {item.action === PENDING_REMOVE_LICENSES ? recurlyStatus : 'Reversed: '}Removing {licenseDifference} license
          {licenseDifference > 1 ? 's' : ''} for a total of {item.newLicenses}
        </span>
      );
    }

    if (
      item.action === PENDING_EDITION_DOWNGRADE ||
      item.action === CANCEL_EDITION_DOWNGRADE ||
      item.action === EDITION_UPGRADE ||
      item.action === EDITION_DOWNGRADE
    ) {
      const oldEdition = GetEditionFromEditionCode(item.oldEdition);
      const newEdition = GetEditionFromEditionCode(item.newEdition);

      if (item.action === PENDING_EDITION_DOWNGRADE || item.action === CANCEL_EDITION_DOWNGRADE)
        events.push(
          <span key="licenseCount">
            {item.action === PENDING_EDITION_DOWNGRADE ? recurlyStatus : 'Reversed: '}Edition changing from {oldEdition} to {newEdition}
          </span>
        );
      else if (item.action === EDITION_UPGRADE || item.action === EDITION_DOWNGRADE)
        events.push(
          <span key="licenseCount">
            Edition changed from {oldEdition} to {newEdition}
          </span>
        );
    }

    if (item.netACV && (item.action === PENDING_REMOVE_LICENSES || item.action === PENDING_EDITION_DOWNGRADE)) {
      if (events.length > 0) {
        events.push(', ');
      }
      events.push(<span key="acv">Net ACV {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netACV)}</span>);
    }

    if (item.action === PENDING_ADDON_REMOVAL || item.action === CANCEL_ADDON_REMOVAL) {
      addComma(addOnEvents);
      addOnEvents.push(
        <span key={`addOns-removal-${item.id}`}>
          {item.action === PENDING_ADDON_REMOVAL ? recurlyStatus : 'Reversed: '}Removing {item.updatedAddOnCodes}
        </span>
      );
    }

    if (item.netAddOnACV && (item.action === PENDING_ADDON_REMOVAL || item.action === CANCEL_ADDON_REMOVAL)) {
      addComma(addOnEvents);
      addOnEvents.push(
        <span key={`acv-${item.id}`}>Net add-on ACV {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netAddOnACV)}</span>
      );
    }

    if (events.length > 0) {
      timeline.push(getEventLogTimelineItems(tooltip, item.netACV, events, item.username, item.createdDate));
    }

    if (addOnEvents.length > 0) {
      timeline.push(getEventLogTimelineItems(tooltip, item.netAddOnACV, addOnEvents, item.username, item.createdDate));
    }
    e++;
  }

  if (deletedDate) {
    timeline.push({
      element: (
        <div key="deleted" className="timeline-item negative">
          <i className={`ui icon remove circle`} />
          <div className="item-body">
            <div className="activities">Account deleted</div>
            <div className="timestamp">{moment.utc(deletedDate).local().format('MM/DD/YYYY h:mm a')}</div>
          </div>
        </div>
      ),
      timestamp: moment.utc(deletedDate),
    });
  }

  return <div className="timeline">{timeline.sort((a, b) => b.timestamp - a.timestamp).map((x) => x.element)}</div>;
}
