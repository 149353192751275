import './UpdateSubscription.scss';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Label } from 'semantic-ui-react';
import { apimCall } from '../../../utility/api';
import { useForm } from '../../../hooks/useForm';
import { Module } from '../../Module';
import { AppFeatures, canIUse } from '../../../utility/SecurityHelper';
import { formatDateToUtc, formatDateToLocal } from '../../../utility/formatting';
import { CommentInput } from '../../commentinput/CommentInput';
import { ClientDto } from '../account-types';
import { SUBSCRIPTION_CODES } from '../../../utility/Constants';

export type UpdateSubscriptionProps = {
  id: string;
  account: ClientDto;
  refreshAccount: () => void;
};

export function UpdateSubscription(props: UpdateSubscriptionProps) {
  const { account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [updateComments, setUpdateComments] = useState('');
  const initialState = {
    licensedUserCount: account.nonUserPricing ? null : account.effectiveLicensedUserCount ?? account.licensedUserCount,
    price: account.nonUserPricing ? null : account.price,
    billingPeriod: account.billingPeriod || 1,
    editionCode: account.pendingSubscriptionCode ?? account.subscriptionCode,
    acv: account.acv || 0,
    isManualInvoice: account.isManualInvoice,
    nonUserPricing: account.nonUserPricing,
    trialEndDate: formatDateToLocal(account.trialEndDate),
    downgradeImmediately: true,
    refund: 'none',
    handleRecurly: 'nothing',
  };
  const [settings, setAccountSettings] = useForm(initialState);

  const save = async () => {
    setSaving(true);
    settings.trialEndDate = formatDateToUtc(settings.trialEndDate, account.trialEndDate);
    apimCall(`/subscriptions`, 'PUT', settings, account.clientId, null, updateComments)
      .then(() => {
        setSaving(false);
        setError('');
        setUpdateComments('');
        refreshAccount();
      })
      .catch((err) => {
        setSaving(false);
        if (err && err.response && err.response.data) {
          setError(err.response.data.message || err.response.data.error);
        } else {
          setError(`Unknown error: ${err}`);
        }
      });
  };

  const calculateDefaultPrice = (editionCode: string, billingPeriod: number, price: number) => {
    return (() => {
      const period = Number(billingPeriod);
      const userPrice = Number(price);
      switch (editionCode) {
        case SUBSCRIPTION_CODES.TRIAL:
        case SUBSCRIPTION_CODES.BASIC:
        case SUBSCRIPTION_CODES.TEACHER:
          return 0;
        case SUBSCRIPTION_CODES.TEAM:
          return period === 12 ? 24 : 30;
        case SUBSCRIPTION_CODES.ADVANCED:
          return period === 12 ? 48 : 60;
        case SUBSCRIPTION_CODES.ENTERPRISE:
          return period === 12 ? 99 : 120;
        default:
          return userPrice;
      }
    })();
  };

  const resetPrice = () => {
    const price = calculateDefaultPrice(settings.editionCode, settings.billingPeriod, settings.price);
    setAccountSettings('price', price);
    setAccountSettings('acv', +price * settings.licensedUserCount * 12);
  };

  useEffect(() => {
    if (
      (!settings.nonUserPricing &&
        (account.subscriptionCode === SUBSCRIPTION_CODES.BASIC || account.subscriptionCode === SUBSCRIPTION_CODES.TRIAL) &&
        settings.editionCode !== SUBSCRIPTION_CODES.BASIC &&
        settings.editionCode !== SUBSCRIPTION_CODES.TRIAL) ||
      settings.editionCode === SUBSCRIPTION_CODES.BASIC ||
      settings.editionCode === SUBSCRIPTION_CODES.TRIAL
    ) {
      resetPrice();
      if (settings.editionCode === SUBSCRIPTION_CODES.BASIC) {
        setAccountSettings('licensedUserCount', 1);
      }
    }
  }, [settings.editionCode]);

  return (
    <div className="update-subscription">
      <Module title="Update Subscription" className="fullwidth-module">
        <table className="ui very basic collapsing celled table">
          <tbody>
            <tr>
              <td>Active Recurly Subscription</td>
              <td>{account.settings.hasActiveRecurlySubscription.toString()}</td>
            </tr>
            <tr>
              <td>Manually Invoiced</td>
              <td>
                <Checkbox
                  checked={settings.isManualInvoice}
                  toggle
                  disabled={!!settings.nonUserPricing}
                  onChange={(e, data) => {
                    setAccountSettings('isManualInvoice', data.checked);
                    if (!data.checked) {
                      setAccountSettings('handleRecurly', 'nothing');
                      setAccountSettings('refund', 'none');
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Non-User Pricing</td>
              <td>
                <Checkbox
                  checked={settings.nonUserPricing}
                  toggle
                  onChange={(e, data) => {
                    setAccountSettings('nonUserPricing', data.checked);
                    if (data.checked) {
                      // non-user pricing requires manual invoicing
                      setAccountSettings('isManualInvoice', true);
                      setAccountSettings('licensedUserCount', null);
                      setAccountSettings('price', null);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>
                <select
                  className="ui dropdown"
                  id="subscriptionId"
                  value={settings.editionCode}
                  onChange={(e) => {
                    setAccountSettings('editionCode', e.target.value);
                  }}
                >
                  {Object.values(SUBSCRIPTION_CODES).map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            {settings.nonUserPricing ? (
              <tr>
                <td>ACV</td>
                <td>
                  <Input
                    type="number"
                    value={settings.acv}
                    onChange={(e) => setAccountSettings('acv', e.target.value)}
                    error={settings.acv.toString() === ''}
                  />
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Licensed Users</td>
                  <td>
                    <Input
                      type="number"
                      value={settings.licensedUserCount}
                      id="UpgradeLicenses"
                      onChange={(e) => {
                        setAccountSettings('licensedUserCount', e.target.value);
                        setAccountSettings('acv', +e.target.value * settings.price * 12);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Price Per User</td>
                  <td>
                    <Input
                      type="number"
                      value={settings.price}
                      id="UpgradePrice"
                      onChange={(e) => {
                        setAccountSettings('price', e.target.value);
                        setAccountSettings('acv', +e.target.value * settings.licensedUserCount * 12);
                      }}
                    />
                  </td>
                  <td>
                    <Button id="resetPrice" secondary onClick={resetPrice}>
                      Reset
                    </Button>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td data-tooltip="1 for monthly, 12 for annual" data-position="top left">
                <i className="info blue circle icon"></i>Billing Period
              </td>
              <td>
                <Input
                  type="number"
                  value={settings.billingPeriod}
                  id="UpgradeBillingPeriod"
                  onChange={(e) => setAccountSettings('billingPeriod', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>ACV</td>
              <td>{settings.acv.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
            </tr>
            {settings.editionCode === SUBSCRIPTION_CODES.TRIAL && (
              <tr>
                <td>Trial End Date</td>
                <td>
                  <Input
                    type="text"
                    style={{ width: '194px' }}
                    value={settings.trialEndDate || ''}
                    onChange={(e) => setAccountSettings('trialEndDate', e.target.value)}
                  ></Input>
                </td>
              </tr>
            )}
            {(settings.price < account.price || settings.licensedUserCount < account.licensedUserCount) && !settings.isManualInvoice && (
              <tr>
                <td>Process Change</td>
                <td>
                  <select className="ui dropdown" id="processChange" onChange={(e) => setAccountSettings('downgradeImmediately', e.target.value === 'true')}>
                    <option value="true" selected>
                      Process Immediately
                    </option>
                    <option value="false">Process at end of Term</option>
                  </select>
                </td>
              </tr>
            )}
            {!account.isManualInvoice && settings.isManualInvoice && account.settings.hasActiveRecurlySubscription && (
              <tr>
                <td>Handle Recurly Subscription</td>
                <td>
                  <select className="ui dropdown" id="handleRecurly" onChange={(e) => setAccountSettings('handleRecurly', e.target.value)}>
                    <option value="nothing" selected>
                      Do Nothing
                    </option>
                    <option value="cancel">Cancel at end of Term</option>
                    <option value="terminate">Terminate Immediately</option>
                  </select>
                </td>
              </tr>
            )}
            {settings.handleRecurly === 'terminate' ||
            (!!settings.downgradeImmediately && settings.editionCode === SUBSCRIPTION_CODES.BASIC && account.settings.hasActiveRecurlySubscription) ? (
              <tr>
                <td>Refund</td>
                <td>
                  <select className="ui dropdown" id="refund" onChange={(e) => setAccountSettings('refund', e.target.value)}>
                    <option value="full">Full</option>
                    <option value="partial">Partial</option>
                    <option value="none" selected>
                      None
                    </option>
                  </select>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {settings.acv > 0 && !account.settings.hasActiveRecurlySubscription && !settings.isManualInvoice && (
          <p>
            This account does not have an active Recurly subscription. Please mark this account as manually invoiced or create a new Subscription in Recurly
            after updating the subscription here.
          </p>
        )}
        {error ? (
          <div className="error-message">
            <Label basic color="red" id="resultMsg">
              {error}
            </Label>
          </div>
        ) : null}
        {account.deletedDate ? null : (
          <div className="ui form">
            <CommentInput placeholder="Comment." value={updateComments} onChange={setUpdateComments} />
            <Button
              id="changeSubscription"
              primary
              onClick={save}
              loading={saving}
              disabled={(settings.nonUserPricing && settings.acv.toString() === '') || !canIUse(AppFeatures.EditFinancialAccountSettings) || !updateComments}
            >
              Update Subscription
            </Button>
          </div>
        )}
      </Module>
    </div>
  );
}
