import React, { useState } from 'react';
import Loader from '../../Loader';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Module } from '../../Module';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import './AccountsSearch.scss';
import { billingPeriodOptions, subscriptionOptions } from '../../../utility/Constants';
import { useApi } from '../../../hooks/useApi';
import { apiCall } from '../../../utility/api';
import Pagination from '../../paging/Pagination';

export function AccountsSearch() {
  const [trialEndDateStart, setTrialEndDateStart] = useState('');
  const [trialEndDateFinish, setTrialEndDateFinish] = useState('');
  const [createdDateEndDateStart, setCreatedDateEndDateStart] = useState('');
  const [createdDateEndDateFinish, setCreatedDateEndDateFinish] = useState('');

  const [subscription, setSubscription] = useState('');
  const [billingPeriod, setBillingPeriod] = useState('');
  const [featureFlagFilter, setFeatureFlagFilter] = useState([]);

  const [hipaa, setHipaa] = useState('');
  const [legacyPricing, setLegacyPricing] = useState('');
  const [nonUserPricing, setNonUserPricing] = useState('');
  const [manualInvoice, setManualInvoice] = useState('');

  const [featureFlags] = useApi<TypeMePlease>('/api/featureflags');

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);

  const search = async () => {
    setPage(1);
    setLoading(true);
    const r = await apiCall(
      `/api/accountssearch?${createParams(
        trialEndDateStart,
        trialEndDateFinish,
        createdDateEndDateStart,
        createdDateEndDateFinish,
        subscription,
        billingPeriod,
        featureFlagFilter,
        hipaa,
        legacyPricing,
        nonUserPricing,
        manualInvoice,
        1,
        pageSize,
        false
      )}`
    );
    setResults(r.data);
    setLoading(false);
  };

  const searchExport = async () => {
    setPage(1);
    setLoading(true);
    const r = await apiCall(
      `/api/accountssearch/export?${createParams(
        trialEndDateStart,
        trialEndDateFinish,
        createdDateEndDateStart,
        createdDateEndDateFinish,
        subscription,
        billingPeriod,
        featureFlagFilter,
        hipaa,
        legacyPricing,
        nonUserPricing,
        manualInvoice,
        1,
        pageSize,
        true
      )}`
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Account Search.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  };

  const changePageSize = async (p) => {
    setPageSize(p);
    setPage(1);
    setLoading(true);
    const r = await apiCall(
      '/api/accountssearch?' +
        createParams(
          trialEndDateStart,
          trialEndDateFinish,
          createdDateEndDateStart,
          createdDateEndDateFinish,
          subscription,
          billingPeriod,
          featureFlagFilter,
          hipaa,
          legacyPricing,
          nonUserPricing,
          manualInvoice,
          1,
          p
        )
    );
    setResults(r.data);
    setLoading(false);
  };

  const changePage = async (pageNumber) => {
    setPage(pageNumber);
    setLoading(true);
    const r = await apiCall(
      '/api/accountssearch?' +
        createParams(
          trialEndDateStart,
          trialEndDateFinish,
          createdDateEndDateStart,
          createdDateEndDateFinish,
          subscription,
          billingPeriod,
          featureFlagFilter,
          hipaa,
          legacyPricing,
          nonUserPricing,
          manualInvoice,
          pageNumber,
          pageSize
        )
    );
    setResults(r.data);
    setLoading(false);
  };

  let hasMorePages = false;
  if (results.length > pageSize) {
    hasMorePages = true;
  }

  const featureFlagOptions = featureFlags
    ? featureFlags.map((x) => {
        return { key: x.flagName, text: x.flagName, value: x.flagName };
      })
    : [];

  const defaultOptions = [
    {
      key: 'any',
      text: 'Any',
      value: 'any',
    },
    {
      key: '1',
      text: 'True',
      value: '1',
    },
    {
      key: '0',
      text: 'False',
      value: '0',
    },
  ];

  return (
    <div className="accounts-search">
      <Helmet>
        <title>Client Search</title>
      </Helmet>
      <Module title="Client Search" className="details-module">
        <div className="body-wrapper">
          <div className="body-left">
            <div className="row">
              <label>Trial End Date</label>
              <Input type="date" onChange={(e, d) => setTrialEndDateStart(d.value)} value={trialEndDateStart} />
              <Input type="date" className="second-input" onChange={(e, d) => setTrialEndDateFinish(d.value)} value={trialEndDateFinish} />
            </div>
            <div className="row">
              <label>Created Date</label>
              <Input type="date" onChange={(e, d) => setCreatedDateEndDateStart(d.value)} value={createdDateEndDateStart} />
              <Input type="date" className="second-input" onChange={(e, d) => setCreatedDateEndDateFinish(d.value)} value={createdDateEndDateFinish} />
            </div>
            <div className="row">
              <label>HIPAA</label>
              <Dropdown
                className="dropdown single"
                fluid
                clearable
                selection
                options={defaultOptions}
                onChange={(e, d) => setHipaa(d.value as string)}
                value={hipaa}
                placeholder="Any"
              />
            </div>
            <div className="row">
              <label>Legacy Pricing</label>
              <Dropdown
                className="dropdown single"
                fluid
                clearable
                selection
                options={defaultOptions}
                onChange={(e, d) => setLegacyPricing(d.value as string)}
                value={legacyPricing}
                placeholder="Any"
              />
            </div>
            <div className="row">
              <label>Non-User Pricing</label>
              <Dropdown
                className="dropdown single"
                fluid
                clearable
                selection
                options={defaultOptions}
                onChange={(e, d) => setNonUserPricing(d.value as string)}
                value={nonUserPricing}
                placeholder="Any"
              />
            </div>
            <div className="row">
              <label>Manually Invoiced</label>
              <Dropdown
                className="dropdown single"
                fluid
                clearable
                selection
                options={defaultOptions}
                onChange={(e, d) => setManualInvoice(d.value as string)}
                value={manualInvoice}
                placeholder="Any"
              />
            </div>
          </div>
          <div className="body-right">
            <div className="row">
              <label>Subscription</label>
              <Dropdown
                className="dropdown"
                placeholder="Select Subscription"
                fluid
                clearable
                selection
                options={subscriptionOptions}
                onChange={(e, d) => setSubscription(d.value as string)}
                value={subscription}
              />
            </div>
            <div className="row">
              <label>Billing Period</label>
              <Dropdown
                className="dropdown"
                placeholder="Select Billing Period"
                fluid
                clearable
                selection
                options={billingPeriodOptions}
                onChange={(e, d) => setBillingPeriod(d.value as string)}
                value={billingPeriod}
              />
            </div>
            <div className="row">
              <label>Feature Flags</label>
              <Dropdown
                className="dropdown"
                placeholder="Select Feature Flags"
                fluid
                clearable
                multiple
                selection
                options={featureFlagOptions}
                onChange={(e, d) => setFeatureFlagFilter(d.value as string[])}
                value={featureFlagFilter}
              />
            </div>
          </div>
          <Button className="primary search" onClick={search}>
            Search
          </Button>
          {results.length === 0 ? (
            ''
          ) : (
            <Button className="primary export" onClick={searchExport}>
              Export
            </Button>
          )}
        </div>
      </Module>

      <div>
        <table className="ui table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Subscription</th>
              <th>Promo Code</th>
              <th>Trial End Date</th>
              <th>Created Date</th>
              <th>ACV</th>
              <th>Company Size</th>
              <th>Employee Count</th>
              <th>Price</th>
              <th>HIPAA</th>
              <th>Billing Period</th>
              <th>Manually Invoiced</th>
              <th>Non-User Pricing</th>
              <th>Client Id</th>
            </tr>
          </thead>
          <tbody>
            {results
              ? results.slice(0, pageSize).map((results) => {
                  return <Results key={results.clientId} results={results} />;
                })
              : null}
          </tbody>
        </table>
        <div className="paging">
          <Pagination
            changePageSize={changePageSize}
            changePage={changePage}
            pageSize={pageSize}
            page={page}
            infinitePaging={true}
            hasMorePages={hasMorePages}
          />
        </div>
      </div>
      <Loader isLoading={loading} />
    </div>
  );
}

// todo: move this into a new file as a presentational component
function Results(props) {
  const { results } = props;
  const {
    clientId,
    name,
    subscriptionCode,
    promoCode,
    trialEndDate,
    createdDate,
    acv,
    companySize,
    licensedUserCount,
    price,
    isHIPAA,
    billingPeriod,
    isManualInvoice,
    nonUserPricing,
  } = results;

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  return (
    <tr>
      <td>
        <a href={`account/${clientId}`}>{name}</a>
      </td>
      <td>{subscriptionCode}</td>
      <td>{promoCode}</td>
      <td>{moment.utc(trialEndDate).local().calendar(null, calendarOptions)}</td>
      <td>{moment.utc(createdDate).local().calendar(null, calendarOptions)}</td>
      <td>{acv}</td>
      <td>{companySize}</td>
      <td>{licensedUserCount}</td>
      <td>{price}</td>
      <td>{isHIPAA ? 'true' : 'false'}</td>
      <td>{billingPeriod}</td>
      <td>{isManualInvoice ? 'true' : 'false'}</td>
      <td>{nonUserPricing ? 'true' : 'false'}</td>
      <td>
        <a href={`account/${clientId}`}>{clientId}</a>
      </td>
    </tr>
  );
}

// todo: move this out into a utils file and test it
function createParams(
  trialEndDateStart: string,
  trialEndDateFinish: string,
  createdDateEndDateStart: string,
  createdDateEndDateFinish: string,
  subscription: string,
  billingPeriod: string,
  featureFlagFilter: TypeMePlease[],
  hipaa: string,
  legacyPricing: string,
  nonUserPricing: string,
  manualInvoice: string,
  pageNumber: number,
  pageSize: number,
  searchExport?: boolean
) {
  const params = [];
  if (trialEndDateStart) {
    params.push(`trialEndDateStart=${trialEndDateStart}`);
  }
  if (trialEndDateFinish) {
    params.push(`trialEndDateFinish=${trialEndDateFinish}`);
  }
  if (createdDateEndDateStart) {
    params.push(`createdDateEndDateStart=${createdDateEndDateStart}`);
  }
  if (createdDateEndDateFinish) {
    params.push(`createdDateEndDateFinish=${createdDateEndDateFinish}`);
  }
  if (subscription) {
    params.push(`subscription=${subscription}`);
  }
  if (billingPeriod) {
    params.push(`billingPeriod=${billingPeriod}`);
  }
  if (featureFlagFilter) {
    params.push(`featureFlagFilter=${featureFlagFilter}`);
  }
  if (hipaa) {
    params.push(`hipaa=${hipaa}`);
  }
  if (legacyPricing) {
    params.push(`legacyPricing=${legacyPricing}`);
  }
  if (nonUserPricing) {
    params.push(`nonUserPricing=${nonUserPricing}`);
  }
  if (manualInvoice) {
    params.push(`manualInvoice=${manualInvoice}`);
  }

  params.push(`isExport=${searchExport}`);
  params.push(`offset=${(pageNumber - 1) * pageSize}`);
  params.push(`fetch=${pageSize + 1}`);

  return params.join('&');
}
