import React, { useEffect, useState } from 'react';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { Accordion, Button, Checkbox, Divider, Dropdown, Form, Icon, Input, Segment, Table } from 'semantic-ui-react';
import './EditFeatureFlagExperiment.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessages } from '../../context/MessagesContext';
import { apiCall } from '../../utility/api';
import { FeatureFlagValue } from './FeatureFlagValue';
import {
  FeatureFlag,
  FeatureFlagDataType,
  FeatureFlagExperiment,
  FeatureFlagExperimentTargetingDetails,
  FeatureFlagExperimentClientType,
  FeatureFlagTargetingFeatureFlag,
} from './types';
import { subscriptionOptions } from '../../utility/Constants';
import Loader from '../Loader';
import DateRangePickerModal from '../DateRangePicker/DateRangePickerModal';
import { RangeModifier } from 'react-day-picker';
import { isAbsolute } from 'path/posix';

interface EditFeatureFlagExperimentProps {
  featureFlag: FeatureFlag;
  featureFlags: FeatureFlag[];
  featureFlagExperiments: FeatureFlagExperiment[];
  onFeatureFlagExperimentSaved: (newFeatureFlagExperiment: FeatureFlagExperiment) => void;
}

export function EditFeatureFlagExperiment(props: EditFeatureFlagExperimentProps) {
  const params = useParams();
  const isNewExperiment = params.featureFlagExperimentId == 'add';
  const featureFlagExperimentId = isNewExperiment ? null : params.featureFlagExperimentId;

  const prepareFeatureFlagExperiment = () => {
    const ffe = (props.featureFlagExperiments || []).find((ffe) => ffe.featureFlagExperimentId === featureFlagExperimentId);
    const setFeatureFlagId = (_: FeatureFlagExperiment): FeatureFlagExperiment => ({ featureFlagId: featureFlagId });
    const setTargetingDetails = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: obj.targetingDetails ? { ...obj.targetingDetails } : {},
    });
    const setTargetingDetailsQualifierSkipSize = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, qualifierSkipSize: obj.targetingDetails.qualifierSkipSize ?? 2 },
    });
    const setTargetingDetailsTimeToRequalifyMinutes = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, timeToRequalifyMinutes: obj.targetingDetails.timeToRequalifyMinutes },
    });
    const setTargetingDetailsClientType = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, clientType: obj.targetingDetails.clientType ?? FeatureFlagExperimentClientType.NewAndExisting },
    });
    const setTargetingDetailsIgnoreInternalAccounts = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, ignoreInternalAccounts: obj.targetingDetails.ignoreInternalAccounts ?? true },
    });
    const setTargetingDetailsSubscriptions = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, subscriptions: obj.targetingDetails.subscriptions ?? [] },
    });
    const setTargetingDetailsFetaureFlags = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({
      targetingDetails: { ...obj.targetingDetails, featureFlags: obj.targetingDetails.featureFlags ?? [] },
    });
    const setStartDate = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({ startDate: obj.startDate ? new Date(`${obj.startDate}Z`) : null });
    const setEndDate = (obj: FeatureFlagExperiment): FeatureFlagExperiment => ({ endDate: obj.endDate ? new Date(`${obj.endDate}Z`) : null });

    return [
      setFeatureFlagId,
      setTargetingDetails,
      setTargetingDetailsClientType,
      setTargetingDetailsIgnoreInternalAccounts,
      setTargetingDetailsSubscriptions,
      setTargetingDetailsFetaureFlags,
      setTargetingDetailsQualifierSkipSize,
      setTargetingDetailsTimeToRequalifyMinutes,
      setStartDate,
      setEndDate,
    ].reduce((value, fn) => ({ ...value, ...fn(value) }), ffe ? { ...ffe } : {});
  };

  const navigate = useNavigate();
  const { featureFlagId, dataType } = props.featureFlag || {};
  const [isUpdating, setIsUpdating] = useState(false);
  const [featureFlagExperiment, setFeatureFlagExperiment] = useState<FeatureFlagExperiment>(prepareFeatureFlagExperiment());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [experimentTargetPercentage, setExperimentTargetPercentage] = useState<number>(featureFlagExperimentId ? null : 50);
  const [featureFlagExperimentSizeEstimation, setFeatureFlagExperimentSizeEstimation] = useState<number>(null);
  const [targetingEstimationAdvancedToggled, setTargetingEstimationAdvancedToggled] = useState<boolean>(false);
  const readOnly = !canIUse(AppFeatures.EditFeatureFlags) || (!!featureFlagExperimentId && featureFlagExperiment.endDate < new Date());
  const { showMessage } = useMessages();
  useEffect(() => {
    setFeatureFlagExperiment(prepareFeatureFlagExperiment());
  }, [props.featureFlagExperiments]);

  const clientTypeOptions = [
    { type: 'New Clients', key: FeatureFlagExperimentClientType.New },
    { type: 'Existing Clients', key: FeatureFlagExperimentClientType.Existing },
    { type: 'New & Existing Clients', key: FeatureFlagExperimentClientType.NewAndExisting },
    { type: 'Anonymous', key: FeatureFlagExperimentClientType.Anonymous },
  ].map((value) => ({ key: value.key, text: value.type, value: value.key }));

  const featureFlagOptions = (props.featureFlags || []).map((ff) => ({ key: ff.featureFlagId, text: ff.flagName, value: ff.featureFlagId }));

  const nonUsedFeatureFlagOptions = (selfFeatureFlagId: string) =>
    featureFlagOptions.filter(
      (pco) => pco.value === selfFeatureFlagId || !(featureFlagExperiment.targetingDetails?.featureFlags ?? []).some((def) => def.featureFlagId === pco.value)
    );

  const getHeaderText = () => {
    return `${featureFlagExperimentId == null ? 'Add' : 'Edit'} Experiment`;
  };

  const isExperimentStartedOrReadonly =
    readOnly ||
    (!!featureFlagId && (props.featureFlagExperiments || []).some((ffe) => ffe.featureFlagExperimentId === featureFlagExperimentId && !!ffe.startDate));

  const getExperimentDateRangeText = () => {
    if (featureFlagExperiment.startDate == null) {
      return 'No Start Date';
    }

    const startDateText = featureFlagExperiment.startDate.toLocaleDateString();
    const endDateText = featureFlagExperiment.endDate == null ? 'Forever' : featureFlagExperiment.endDate.toLocaleDateString();
    return `${startDateText} - ${endDateText}`;
  };

  const setfeatureFlagExperimentProp = (propName: keyof FeatureFlagExperiment, value) => {
    setFeatureFlagExperiment({ ...featureFlagExperiment, ...{ [propName]: value } });
  };

  const setExperimentDefaultValueProp = (value) => {
    dataType === FeatureFlagDataType.BoolValue && setfeatureFlagExperimentProp('experimentGroupBoolValue', value);
    dataType === FeatureFlagDataType.NumberValue && setfeatureFlagExperimentProp('experimentGroupNumberValue', value);
    dataType === FeatureFlagDataType.DecimalValue && setfeatureFlagExperimentProp('experimentGroupDecimalValue', value);
    dataType === FeatureFlagDataType.JsonValue && setfeatureFlagExperimentProp('experimentGroupJsonValue', value);
    dataType === FeatureFlagDataType.StringValue && setfeatureFlagExperimentProp('experimentGroupStringValue', value);
  };

  const setExperimentDates = (range: RangeModifier) => {
    setFeatureFlagExperiment({
      ...featureFlagExperiment,
      ...{
        startDate: range.from,
        endDate: range.to,
      },
    });
    setIsDatePickerOpen(false);
  };

  const setTargetingDetailsProp = (propName: keyof FeatureFlagExperimentTargetingDetails, value) => {
    setFeatureFlagExperiment({
      ...featureFlagExperiment,
      targetingDetails: {
        ...featureFlagExperiment.targetingDetails,
        ...{ [propName]: value },
      },
    });
  };

  const setExpimentTargetPercentage = (value: number) => {
    const validValue = value < 0 ? 0 : value > 100 ? 100 : value;

    const experimentSize = featureFlagExperimentSizeEstimation ? Math.round(featureFlagExperimentSizeEstimation * (validValue / 100)) : null;
    const qualifierSkipSize = experimentSize ? Math.round(featureFlagExperimentSizeEstimation / experimentSize) : 100 / validValue;

    setFeatureFlagExperiment({
      ...featureFlagExperiment,
      targetingDetails: {
        ...featureFlagExperiment.targetingDetails,
        ...{
          qualifierSkipSize: qualifierSkipSize,
          experimentSize: experimentSize,
        },
      },
    });

    setExperimentTargetPercentage(validValue);
  };

  const getTargetingFeatureFlagValue = (featureFlagTarget: FeatureFlagTargetingFeatureFlag) => {
    const featureFlag = getTargetingFeatureFlagFeatureFlag(featureFlagTarget.featureFlagId);

    return featureFlag?.dataType === FeatureFlagDataType.BoolValue
      ? featureFlagTarget?.boolValue
      : featureFlag?.dataType === FeatureFlagDataType.NumberValue
        ? featureFlagTarget?.numberValue
        : featureFlag?.dataType === FeatureFlagDataType.DecimalValue
          ? featureFlagTarget?.decimalValue
          : featureFlag?.dataType === FeatureFlagDataType.JsonValue
            ? featureFlagTarget?.jsonValue
            : featureFlag?.dataType === FeatureFlagDataType.StringValue
              ? featureFlagTarget?.stringValue
              : null;
  };

  const getTargetingFeatureFlagFeatureFlag = (featureFlagId: string): FeatureFlag => {
    return (props.featureFlags || []).find((ff) => ff.featureFlagId === featureFlagId);
  };

  const setTargetingFeatureFlagValue = (index: number, value) => {
    const featureFlagTargets = featureFlagExperiment?.targetingDetails.featureFlags ?? [];
    const featureFlagTarget = featureFlagTargets[index];
    const featureFlag = getTargetingFeatureFlagFeatureFlag(featureFlagTarget.featureFlagId);
    if (featureFlag == null) {
      return;
    }

    featureFlagTarget.boolValue = featureFlag.dataType === FeatureFlagDataType.BoolValue ? value : null;
    featureFlagTarget.numberValue = featureFlag.dataType === FeatureFlagDataType.NumberValue ? value : null;
    featureFlagTarget.decimalValue = featureFlag.dataType === FeatureFlagDataType.DecimalValue ? value : null;
    featureFlagTarget.jsonValue = featureFlag.dataType === FeatureFlagDataType.JsonValue ? value : null;
    featureFlagTarget.stringValue = featureFlag.dataType === FeatureFlagDataType.StringValue ? value : null;

    featureFlagTargets[index] = { ...featureFlagTarget };
    const targetingDetails = { ...(featureFlagExperiment.targetingDetails ?? {}) };
    targetingDetails.featureFlags = [...featureFlagTargets];
    setFeatureFlagExperiment({ ...featureFlagExperiment, targetingDetails: targetingDetails });
  };

  const setTargetingFeatureFlagFeatureFlagId = (index: number, featureFlagId: string) => {
    const featureFlagTargets = [...(featureFlagExperiment?.targetingDetails.featureFlags ?? [])];
    const featureFlag = getTargetingFeatureFlagFeatureFlag(featureFlagId);
    if (featureFlag == null) {
      return;
    }

    featureFlagTargets[index] = { featureFlagId: featureFlag.featureFlagId };
    const targetingDetails = { ...(featureFlagExperiment.targetingDetails ?? {}) };
    targetingDetails.featureFlags = featureFlagTargets;
    setFeatureFlagExperiment({ ...featureFlagExperiment, targetingDetails: targetingDetails });
  };

  const getDefaultValueProp = () => {
    return props.featureFlag?.dataType === FeatureFlagDataType.BoolValue
      ? featureFlagExperiment?.experimentGroupBoolValue
      : props.featureFlag?.dataType === FeatureFlagDataType.NumberValue
        ? featureFlagExperiment?.experimentGroupNumberValue
        : props.featureFlag?.dataType === FeatureFlagDataType.DecimalValue
          ? featureFlagExperiment?.experimentGroupDecimalValue
          : props.featureFlag?.dataType === FeatureFlagDataType.JsonValue
            ? featureFlagExperiment?.experimentGroupJsonValue
            : props.featureFlag?.dataType === FeatureFlagDataType.StringValue
              ? featureFlagExperiment?.experimentGroupStringValue
              : null;
  };

  const getFeatureFlagExperimentForSave = () => {
    const updatedFeatureFlag = { ...featureFlagExperiment };

    //sanitize the targeting feature flags
    (updatedFeatureFlag.targetingDetails?.featureFlags).forEach((tff) => {
      const featureFlag = getTargetingFeatureFlagFeatureFlag(tff.featureFlagId);
      tff.boolValue = featureFlag.dataType === FeatureFlagDataType.BoolValue ? tff.boolValue : null;
      tff.numberValue = featureFlag.dataType === FeatureFlagDataType.NumberValue ? tff.numberValue : null;
      tff.decimalValue = featureFlag.dataType === FeatureFlagDataType.DecimalValue ? tff.decimalValue : null;
      tff.jsonValue = featureFlag.dataType === FeatureFlagDataType.JsonValue ? tff.jsonValue : null;
      tff.stringValue = featureFlag.dataType === FeatureFlagDataType.StringValue ? tff.stringValue : null;
    });

    return updatedFeatureFlag;
  };

  const validateForSave = () => {
    if (!featureFlagExperiment?.experimentName || !featureFlagExperiment?.description || !featureFlagExperiment?.segmentId) {
      showMessage(`Please fill out all required fields before saving.`);
      return false;
    }
    return true;
  };

  const onBackClick = () => {
    navigate('../experiments');
  };

  const onStatsClick = () => {
    navigate('./stats');
  };

  const onOpenDatePickerClick = () => setIsDatePickerOpen(true);
  const onCloseDatePickerClick = () => setIsDatePickerOpen(false);

  const onDeleteTargetingFeatureFlagClick = (idx: number) => {
    const newTargetingFeatureFlags = [...(featureFlagExperiment.targetingDetails?.featureFlags ?? [])];
    newTargetingFeatureFlags.splice(idx, 1);
    const targetingDetails = { ...(featureFlagExperiment.targetingDetails ?? {}) };
    targetingDetails.featureFlags = newTargetingFeatureFlags;
    setFeatureFlagExperiment({ ...featureFlagExperiment, targetingDetails: targetingDetails });
  };

  const onTargetingEstimationAdvancedClick = () => {
    setTargetingEstimationAdvancedToggled(!targetingEstimationAdvancedToggled);
  };

  const onAddTargetingFeatureFlagClick = () => {
    const targetingDetails = { ...(featureFlagExperiment.targetingDetails ?? {}) };
    targetingDetails.featureFlags = [...(targetingDetails.featureFlags || [])];
    targetingDetails.featureFlags.push({
      featureFlagId: props.featureFlags.find((ff) => !targetingDetails.featureFlags.some((existingFF) => existingFF.featureFlagId === ff.featureFlagId))
        ?.featureFlagId,
    });
    setFeatureFlagExperiment({ ...featureFlagExperiment, targetingDetails: targetingDetails });
  };

  const onEstimateSizeClick = async () => {
    setIsUpdating(true);

    const featureFlagExperimentEstimation = getFeatureFlagExperimentForSave();
    const tryEnd = async () => {
      try {
        const result = await apiCall(`/api/featureFlags/${featureFlagId}/featureflagexperiments/estimatedsize`, 'POST', {
          ...featureFlagExperimentEstimation,
        });

        if (result.status !== 200) {
          showMessage('Failed to estimate the size of the experiment');
          return;
        }
        setFeatureFlagExperimentSizeEstimation(result.data);
      } catch (e) {
        console.error(e);
        showMessage('Failed to estimate the size of the experiment');
      }
    };

    await tryEnd();
    setIsUpdating(false);
  };

  const onSaveClick = async () => {
    if (!validateForSave()) {
      return;
    }

    setIsUpdating(true);

    const updatedFeatureFlag = getFeatureFlagExperimentForSave();
    const trySave = async () => {
      try {
        const result = await (featureFlagExperiment?.featureFlagExperimentId == null
          ? apiCall(`/api/featureFlags/${featureFlagId}/featureflagexperiments`, 'POST', { ...featureFlagExperiment })
          : apiCall(`/api/featureFlags/${featureFlagId}/featureflagexperiments/${featureFlagExperiment.featureFlagExperimentId}`, 'PUT', updatedFeatureFlag));

        if (result.status !== 200) {
          showMessage('Failed to save the experiment');
          return;
        }
        props?.onFeatureFlagExperimentSaved(result.data as FeatureFlagExperiment);
        navigate('../experiments');
      } catch (e) {
        console.log(e);
        showMessage('Failed to save the experiment');
      }
    };

    await trySave();
    setIsUpdating(false);
  };

  const onEndClick = async () => {
    setIsUpdating(true);

    const tryEnd = async () => {
      try {
        const result = await apiCall(`/api/featureFlags/${featureFlagId}/featureflagexperiments/${featureFlagExperiment.featureFlagExperimentId}`, 'PUT', {
          ...featureFlagExperiment,
          endDate: new Date(),
        });

        if (result.status !== 200) {
          showMessage('Failed to stop the experiment');
          return;
        }
        props?.onFeatureFlagExperimentSaved(result.data as FeatureFlagExperiment);
      } catch (e) {
        console.error(e);
        showMessage('Failed to stop the experiment');
      }
    };

    await tryEnd();
    setIsUpdating(false);
  };

  return (
    <div className="edit-feature-flag-experiment">
      <Loader isLoading={isUpdating} />
      <div className="header">
        <Button className="back-button" onClick={onBackClick} circular icon="arrow alternate circle left outline"></Button>
        <h2 className="feature-title">{getHeaderText()}</h2>
        {!isNewExperiment && (
          <a className="sub-header" onClick={onStatsClick}>
            See Stats
          </a>
        )}
      </div>
      <div className="ffe-body">
        <div className="ffe-field-group">
          <div className="ffe-field-value ffe-name">
            <label>Name*</label>
            <Input
              fluid
              placeholder="Experiment Do People Want This Feature?..."
              disabled={readOnly}
              value={featureFlagExperiment.experimentName ?? ''}
              onChange={(_, data) => setfeatureFlagExperimentProp('experimentName', data.value)}
            />
          </div>
          <div className="ffe-field-value ffe-segment-id">
            <label>Segment ID*</label>
            <Input
              fluid
              placeholder=""
              disabled={isExperimentStartedOrReadonly}
              value={featureFlagExperiment.segmentId ?? ''}
              onChange={(_, data) => setfeatureFlagExperimentProp('segmentId', data.value)}
            />
          </div>
          <div className="ffe-field-value ffe-segment-historical-id">
            <label>Segment Historical ID</label>
            <Input
              fluid
              placeholder=""
              disabled={true}
              value={featureFlagExperiment.segmentHistoricalId ?? 'Auto Generated'}
              onChange={(_, data) => setfeatureFlagExperimentProp('segmentHistoricalId', data.value)}
            />
          </div>
        </div>

        <div className="ffe-field-value ffe-description">
          <label>Description*</label>
          <Input
            fluid
            placeholder="All The Deets..."
            disabled={readOnly}
            value={featureFlagExperiment.description ?? ''}
            onChange={(_, data) => setfeatureFlagExperimentProp('description', data.value)}
          />
        </div>

        <div className="ffe-field-value ffe-dates">
          <label>Experiment Date Range</label>
          <Button onClick={onOpenDatePickerClick}>
            <Icon name="calendar outline" />
            {getExperimentDateRangeText()}
          </Button>
          <DateRangePickerModal
            open={isDatePickerOpen}
            startDate={featureFlagExperiment.startDate}
            endDate={featureFlagExperiment.endDate}
            onClose={onCloseDatePickerClick}
            onDoneClick={setExperimentDates}
          />
        </div>

        <div className="ffe-field-group">
          <div className="ffe-field-value ffe-experimment-default-value">
            <label>Feature Value For Experiment Group</label>
            {isExperimentStartedOrReadonly && <label className="sub-label">**Value can not be changed after the experiment has started</label>}
            <FeatureFlagValue
              dataType={dataType}
              value={getDefaultValueProp()}
              onChange={setExperimentDefaultValueProp}
              disabled={isExperimentStartedOrReadonly}
            />
          </div>
        </div>

        <span className="subheader-label">Targeting Details</span>
        <Divider />

        <div className="ffe-field-group">
          <div className="ffe-field-value ffe-client-types">
            <label>Client Type</label>
            <Dropdown
              placeholder="Client Type"
              search
              selection
              value={featureFlagExperiment.targetingDetails.clientType}
              options={clientTypeOptions}
              onChange={(_e, data) => setTargetingDetailsProp('clientType', data.value)}
            />
          </div>
        </div>
        <div className="ffe-field-group">
          <div className="ffe-field-value ffe-client-types">
            <label>Ignore Internal Accounts</label>
            <Checkbox
              checked={featureFlagExperiment.targetingDetails.ignoreInternalAccounts}
              onChange={(_e, data) => setTargetingDetailsProp('ignoreInternalAccounts', data.checked)}
            />
          </div>
        </div>

        <div className="ffe-field-value ffe-subscription-types">
          <label>Subscription Types</label>
          <Dropdown
            placeholder="Subscription Types"
            fluid
            multiple
            search
            selection
            value={featureFlagExperiment.targetingDetails.subscriptions}
            options={subscriptionOptions}
            onChange={(_e, data) => setTargetingDetailsProp('subscriptions', data.value)}
          />
        </div>
        <div className="ffe-field-value ffe-feature-flags">
          <label>Feature Flags Prequisites</label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Feature Flag</Table.HeaderCell>
                <Table.HeaderCell>Targeting Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(featureFlagExperiment.targetingDetails.featureFlags || []).map((targetingFF, idx) => (
                <Table.Row key={`target-feature-flag-${idx}`}>
                  <Table.Cell collapsing>
                    <Button icon="minus circle" className="remove-row-button" disabled={readOnly} onClick={() => onDeleteTargetingFeatureFlagClick(idx)} />
                  </Table.Cell>
                  <Table.Cell className="feature-flags">
                    <Dropdown
                      placeholder="Features Flags"
                      fluid
                      search
                      selection
                      value={targetingFF.featureFlagId}
                      options={nonUsedFeatureFlagOptions(targetingFF.featureFlagId)}
                      onChange={(_e, data) => setTargetingFeatureFlagFeatureFlagId(idx, data.value?.toString())}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FeatureFlagValue
                      dataType={getTargetingFeatureFlagFeatureFlag(targetingFF.featureFlagId).dataType}
                      value={getTargetingFeatureFlagValue(targetingFF)}
                      onChange={(value) => setTargetingFeatureFlagValue(idx, value)}
                      disabled={readOnly}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  {!readOnly && (
                    <Button onClick={onAddTargetingFeatureFlagClick} className="primary" floated="right">
                      <Icon name="plus circle" /> Add Feature Flag Target
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>

        <span className="subheader-label">Experiment Sizing</span>
        <Divider />

        <div className="ffe-field-group">
          <div className="ffe-field-value ffe-estimate-size">
            <label>{`Qualifed Clients: ${featureFlagExperimentSizeEstimation == null ? 'Unknown' : featureFlagExperimentSizeEstimation}`}</label>
            <Button className="primary" onClick={onEstimateSizeClick}>
              Estimate
            </Button>
          </div>
          {
            <div className="ffe-field-value ffe-estimate-size">
              <label>Percentage To Target (Click estimate first to get more accurate results)</label>
              <Input
                type="number"
                step={1}
                max={100}
                min={0}
                fluid
                disabled={readOnly}
                value={experimentTargetPercentage ?? ''}
                onChange={(_, data) => setExpimentTargetPercentage(Number.parseInt(data.value?.toString() || '0'))}
              />
            </div>
          }
        </div>

        <div className="ffe-field-group">
          <div className="ffe-field-value">
            <Accordion fluid styled>
              <Accordion.Title active={targetingEstimationAdvancedToggled} index={0} onClick={onTargetingEstimationAdvancedClick}>
                Advanced Experiment Sizing
              </Accordion.Title>
              <Accordion.Content active={targetingEstimationAdvancedToggled}>
                <div className="ffe-field-group">
                  <div className="ffe-field-value ffe-experiment-size">
                    <label>Experiment Size</label>
                    <Input
                      type="number"
                      step={1}
                      fluid
                      disabled={readOnly}
                      value={featureFlagExperiment.targetingDetails.experimentSize ?? ''}
                      onChange={(_, data) => setTargetingDetailsProp('experimentSize', Number.parseInt(data.value?.toString() || null))}
                    />
                  </div>
                  <div className="ffe-field-value ffe-qualififer-skip-size">
                    <label>Experiment Spread</label>
                    <Input
                      type="number"
                      step={1}
                      fluid
                      disabled={readOnly}
                      value={featureFlagExperiment.targetingDetails.qualifierSkipSize ?? ''}
                      onChange={(_, data) => setTargetingDetailsProp('qualifierSkipSize', Number.parseInt(data.value?.toString() || '0'))}
                    />
                  </div>
                  <div className="ffe-field-value ffe-time-to-requalify-minutes">
                    <label>Requalify Minutes</label>
                    <Input
                      type="number"
                      step={1}
                      min={1}
                      fluid
                      disabled={readOnly}
                      value={featureFlagExperiment.targetingDetails.timeToRequalifyMinutes ?? ''}
                      onChange={(_, data) => setTargetingDetailsProp('timeToRequalifyMinutes', Number.parseInt(data.value?.toString() || null))}
                    />
                  </div>
                </div>
              </Accordion.Content>
            </Accordion>
          </div>
        </div>

        <div className="form-buttons">
          <Button className="tertiary" negative onClick={onEndClick}>
            End Experiment
          </Button>
          <Button className="primary" onClick={onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
