import React, { useState } from 'react';
import { Button, Input, Label } from 'semantic-ui-react';
import { apiCall } from '../../../utility/api';
import { useForm } from '../../../hooks/useForm';
import { Module } from '../../Module';
import './UpdateSubscription.scss';
import { formatDateToLocal, formatDateToUtc } from '../../../utility/formatting';
import { deepClone } from '../../../utility/deepClone';
import moment from 'moment';
import { SUBSCRIPTION_CODES } from '../../../utility/Constants';

export type UpdateTrialSubscriptionProps = {
  id: string;
  account: TypeMePlease;
  refreshAccount: () => void;
};

export function UpdateTrialSubscription(props: UpdateTrialSubscriptionProps) {
  const { account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const [settings, setAccountSettings] = useForm({
    editionCode: account.subscriptionCode,
    trialEndDate: formatDateToLocal(account.trialEndDate),
  });

  const save = async () => {
    setSaving(true);
    const request = deepClone(settings);
    request.trialEndDate = formatDateToUtc(settings.trialEndDate, account.trialEndDate);
    apiCall(`/api/account/${account.clientId}/subscription/trial`, 'PUT', request)
      .then(() => {
        setSaving(false);
        setError('');
        refreshAccount();
      })
      .catch((err) => {
        setSaving(false);
        setError(err.response.data.message || err.response.data.error);
      });
  };

  return (
    <div className="update-subscription">
      <Module title="Trial Subscription" className="fullwidth-module">
        <table className="ui very basic collapsing celled table">
          <tbody>
            <tr>
              <td data-tooltip="When switching to gf-basic, the Trial End Date will be set to expire at that time" data-position="top left">
                <i className="info blue circle icon"></i>Subscription
              </td>
              <td>
                <select
                  value={settings.editionCode}
                  className="ui dropdown"
                  id="subscriptionId"
                  onChange={(e) => {
                    setAccountSettings('editionCode', e.target.value);
                    if (e.target.value === SUBSCRIPTION_CODES.BASIC) {
                      setAccountSettings('trialEndDate', moment().format('MM/DD/YYYY h:mm:ss a'));
                    }
                  }}
                >
                  <option value="gf-trial">gf-trial</option>
                  <option value="gf-basic">gf-basic</option>
                </select>
              </td>
            </tr>
            {
              <tr>
                <td data-tooltip="The Trial End Date can only be updated for gf-trial subscriptions." data-position="top left">
                  <i className="info blue circle icon"></i>Trial End Date
                </td>
                <td>
                  <Input
                    type="text"
                    disabled={settings.editionCode === SUBSCRIPTION_CODES.BASIC}
                    style={{ width: '194px' }}
                    value={settings.trialEndDate || ''}
                    onChange={(e) => setAccountSettings('trialEndDate', e.target.value)}
                  ></Input>
                </td>
              </tr>
            }
          </tbody>
        </table>
        {error ? (
          <div className="error-message">
            <Label basic color="red" id="resultMsg">
              {error}
            </Label>
          </div>
        ) : null}
        {account.deletedDate ? null : (
          <Button id="changeSubscription" primary onClick={save} loading={saving} disabled={settings.trialEndDate == ''}>
            Update Trial Subscription
          </Button>
        )}
      </Module>
    </div>
  );
}
