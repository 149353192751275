import { SUBSCRIPTION_CODES } from '../../../utility/Constants';
import { AppItemPermissionLevel, ClientAppPermissions } from '../../apps/apps-types.d';

export const getSubscriptionCodePriority = (subscriptionCode: string) => {
  switch (subscriptionCode) {
    case SUBSCRIPTION_CODES.TRIAL:
      return -1;
    case SUBSCRIPTION_CODES.BASIC:
      return 0;
    case SUBSCRIPTION_CODES.TEAM:
      return 1;
    case SUBSCRIPTION_CODES.ADVANCED:
      return 2;
    case SUBSCRIPTION_CODES.ENTERPRISE:
      return 3;
    default:
      return 0; // Default case if subscription code does not match
  }
};

export const hasCommandTierPermission = (commandTier: string, subscriptionCode: string) => {
  switch (commandTier) {
    case AppItemPermissionLevel.Trial:
      return getSubscriptionCodePriority(subscriptionCode) >= -1;
    case AppItemPermissionLevel.Team:
      return getSubscriptionCodePriority(subscriptionCode) >= 1;
    case AppItemPermissionLevel.Advanced:
      return getSubscriptionCodePriority(subscriptionCode) >= 2;
    case AppItemPermissionLevel.Enterprise:
      return getSubscriptionCodePriority(subscriptionCode) >= 3;
  }
};

export const hasCommandPermission = (
  commandName: string,
  commandPermissionLevel: string,
  clientAppPermissions: ClientAppPermissions,
  subscriptionCode: string
) => {
  if (commandPermissionLevel === 'addon' || commandPermissionLevel === 'unlisted') {
    if (clientAppPermissions?.permissions?.find((x) => x.name === commandName)) {
      return true;
    }
  } else {
    if (hasCommandTierPermission(commandPermissionLevel, subscriptionCode)) {
      return true;
    } else if (clientAppPermissions?.permissions?.find((x) => x.name === commandName)) {
      return true;
    }
  }
  return false;
};

export const getFriendlyPermissionLevelName = (permissionLevel: AppItemPermissionLevel) => {
  switch (permissionLevel) {
    case AppItemPermissionLevel.Trial:
      return 'Trial';
    case AppItemPermissionLevel.Team:
      return 'Team';
    case AppItemPermissionLevel.Advanced:
      return 'Advanced';
    case AppItemPermissionLevel.Enterprise:
      return 'Enterprise';
    case AppItemPermissionLevel.AddOn:
      return 'Add On';
    case AppItemPermissionLevel.Unlisted:
      return 'Unlisted';
    case AppItemPermissionLevel.Deprecated:
      return 'Deprecated';
  }
};
