import React, { useState } from 'react';
import { Input, Button, Checkbox, Dropdown, Modal } from 'semantic-ui-react';
import { apiCall, apimCall } from '../../utility/api';
import { useForm } from '../../hooks/useForm';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { Module } from '../Module';
import { dateFormatOptions, exportDateFormatOptions, timeFormatOptions } from '../../utility/Constants';
import { ClientDto } from './account-types';
import { useMessages } from '../../context/MessagesContext';
import { CommentInput } from '../commentinput/CommentInput';

export type AccountSettingsProps = {
  id: string;
  account: ClientDto;
  refreshAccount: () => void;
};

export type Localization = {
  dateFormat: string;
  timeFormat: string;
  dateEditorOrder: string;
};

export type InitialState = {
  billingEmail: string;
  displayName: string;
  exportDelimiter: string;
  exportNewlines: boolean;
  fromEmail: string;
  emailFromDisplay: string;
  replyToEmail: string;
  smtpServer: string;
  smtpPort: string;
  smtpUsername: string;
  smtpEnableSSL: boolean;
  passwordSettings: any;
  localization?: Localization;
};

export function AccountSettings(props: AccountSettingsProps) {
  const { id, account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [comments, setComments] = useState('');
  const [recoverModalOpen, setRecoverModalOpen] = useState(false);
  const [error, setError] = useState('');
  const { showMessage } = useMessages();
  const initialState: InitialState = {
    billingEmail: account.settings.billingEmail,
    displayName: account.settings.displayName,
    exportDelimiter: account.settings.exportDelimiter,
    exportNewlines: account.settings.exportNewlines,
    fromEmail: account.settings.fromEmail,
    emailFromDisplay: account.settings.emailFromDisplay,
    replyToEmail: account.settings.replyToEmail,
    smtpServer: account.settings.smtpServer,
    smtpPort: account.settings.smtpPort,
    smtpUsername: account.settings.smtpUsername,
    smtpEnableSSL: account.settings.smtpEnableSSL,
    passwordSettings: account.settings.passwordSettings,
  };

  const [accountSettings, setAccountSettings] = useForm(initialState);

  const [localization, setLocalization] = useForm<Localization>({
    dateFormat: account.settings.localization && account.settings.localization.dateFormat ? account.settings.localization.dateFormat : '',
    timeFormat: account.settings.localization && account.settings.localization.timeFormat ? account.settings.localization.timeFormat : '',
    dateEditorOrder: account.settings.localization && account.settings.localization.dateEditorOrder ? account.settings.localization.dateEditorOrder : '',
  });

  const save = async () => {
    setSaving(true);
    accountSettings.localization = localization;
    const request = { settings: accountSettings, comments };
    try {
      await apiCall(`/api/account/${id}/accountsettings`, 'PUT', request);
      setComments('');
      refreshAccount();
      setSaving(false);
    } catch {
      setSaving(false);
      showMessage('Failed to save settings');
    }
  };

  const handleRecovery = () => {
    setSaving(true);
    const request = { clientId: id };
    apimCall(`/accounts/recover`, 'POST', request)
      .then(() => {
        refreshAccount();
        setSaving(false);
        setRecoverModalOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        setError(
          err.response.data.count > 0
            ? `The following users are preventing this account from being recovered: ${err.response.data.data}`
            : 'There was an error recovering this account'
        );
      });
  };

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';
  const ssoProviderOrder = ['goformz', 'google', 'microsoft', 'apple'];
  const defaultIdentityProviderSettings = [
    { id: 'apple', active: false, type: 4 },
    { id: 'goformz', active: true, type: 1 },
    { id: 'google', active: false, type: 2 },
    { id: 'microsoft', active: false, type: 3 },
  ];
  const ssoProviderList = (account?.settings?.identityProviderSettings || defaultIdentityProviderSettings)
    .slice()
    .sort((a, b) => {
      // Match sort on web app
      const aIndex = ssoProviderOrder.indexOf(a.id);
      const bIndex = ssoProviderOrder.indexOf(b.id);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      }
      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1) {
        return -1;
      }
      return aIndex - bIndex;
    })
    .map((ssoItem) => {
      // Match display on web app
      if (ssoItem.id === 'goformz') {
        return {
          ...ssoItem,
          id: 'GoFormz',
        };
      } else {
        return {
          ...ssoItem,
          id: capitalize(ssoItem.id),
        };
      }
    })
    .map((item, index) => {
      return (
        <React.Fragment>
          <div className="cell">
            <Checkbox key={index} label={item.id} checked={item.active} toggle disabled />
            {item.type === 0 && canIUse(AppFeatures.EditAllAccountSettings) && (
              <span style={{ paddingLeft: '50px' }}>
                {' '}
                <a href={`/SsoIdp/Edit/${item.id}`}>Edit Custom SSO</a>
              </span>
            )}
          </div>
          <br />
        </React.Fragment>
      );
    });

  return (
    <div className="settings">
      <div className="modules">
        <Module title="Account Settings" className="fullwidth-module">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>Display Company Name</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.displayName || ''}
                    onChange={(e) => setAccountSettings('displayName', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td data-tooltip="This field only accepts one email" data-position="top left">
                  <i className="info blue circle icon"></i>Account Billing Email
                </td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.billingEmail || ''}
                    onChange={(e) => setAccountSettings('billingEmail', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditBillingEmail) || account.isManualInvoice}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Export Delimiter</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.exportDelimiter || ''}
                    onChange={(e) => setAccountSettings('exportDelimiter', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Export New Lines</td>
                <td>
                  <Checkbox
                    checked={accountSettings.exportNewlines}
                    toggle
                    onChange={(e, data) => setAccountSettings('exportNewlines', data.checked)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>From Email</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.fromEmail || ''}
                    onChange={(e) => setAccountSettings('fromEmail', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>From Display</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.emailFromDisplay || ''}
                    onChange={(e) => setAccountSettings('emailFromDisplay', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Reply To Email</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.replyToEmail || ''}
                    onChange={(e) => setAccountSettings('replyToEmail', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>SMTP Server</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.smtpServer || ''}
                    onChange={(e) => setAccountSettings('smtpServer', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>SMTP Port</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.smtpPort || ''}
                    onChange={(e) => setAccountSettings('smtpPort', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>User Name</td>
                <td>
                  <Input
                    type="text"
                    value={accountSettings.smtpUsername || ''}
                    onChange={(e) => setAccountSettings('smtpUsername', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Enable TLS/SSL</td>
                <td>
                  <Checkbox
                    checked={accountSettings.smtpEnableSSL}
                    toggle
                    onChange={(e, data) => setAccountSettings('smtpEnableSSL', data.checked)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Time Format</td>
                <td>
                  <div className="cell">
                    <Dropdown
                      selection
                      placeholder="Select Time Format"
                      value={localization.timeFormat}
                      options={timeFormatOptions.map((timeFormatOptions) => {
                        return { text: timeFormatOptions.text, value: timeFormatOptions.value };
                      })}
                      onChange={(e, data) => setLocalization('timeFormat', data.value)}
                      loading={false}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Date Format</td>
                <td>
                  <div className="cell">
                    <Dropdown
                      selection
                      placeholder="Select Date Format"
                      value={localization.dateFormat}
                      options={dateFormatOptions.map((dateFormatOptions) => {
                        return {
                          text: dateFormatOptions.text,
                          value: dateFormatOptions.value,
                          format: dateFormatOptions.FormatDEO,
                        };
                      })}
                      onChange={(e, data) => {
                        setLocalization('dateFormat', data.value);
                        setLocalization('dateEditorOrder', data.options.find((option) => option.value === data.value).format);
                      }}
                      loading={false}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Export Timezone</td>
                <td>
                  <div className="cell">{account.settings.exportTimezone || 'UTC'}</div>
                </td>
              </tr>
              <tr>
                <td>Export Date Format</td>
                <td>
                  <div className="cell">
                    {exportDateFormatOptions.find((x) => x.value === (account.settings.exportDateFormat || 'yyyy-MM-ddThh:mm:ss')).text}
                  </div>
                </td>
              </tr>
              {ssoProviderList && ssoProviderList.length > 0 && (
                <tr>
                  <td>SSO Settings</td>
                  <td>{ssoProviderList}</td>
                </tr>
              )}
              <tr>
                <td>Password Expiration</td>
                <td>
                  <Checkbox checked={!!accountSettings.passwordSettings?.passwordExpirationDays} toggle disabled={true} />
                </td>
              </tr>
            </tbody>
          </table>
          {account.deletedDate ? null : (
            <div className="ui form">
              <br />
              <CommentInput placeholder="Comment." value={comments} onChange={setComments} />
              <br />
              <Button
                className="primary"
                onClick={save}
                loading={saving}
                disabled={!comments || !accountSettings.displayName || !canIUse(AppFeatures.EditAllAccountSettings)}
              >
                Save
              </Button>
            </div>
          )}
        </Module>
        {account.deletedDate && canIUse(AppFeatures.EditAllAccountSettings) && account.purgeDate != null ? (
          <td data-tooltip="Account PurgeDate Set" data-position="top left">
            <Module>
              <Button color="red" onClick={() => setRecoverModalOpen(true)} disabled={account.purgeDate != null}>
                Recover Account
              </Button>
            </Module>
          </td>
        ) : account.deletedDate && canIUse(AppFeatures.EditAllAccountSettings) ? (
          <Module>
            <Button color="red" onClick={() => setRecoverModalOpen(true)} disabled={account.purgeDate != null}>
              Recover Account
            </Button>
          </Module>
        ) : null}
      </div>
      <Modal open={recoverModalOpen} onClose={() => setRecoverModalOpen(false)} dimmer="inverted">
        <Modal.Header>Confirm Recovery</Modal.Header>
        <Modal.Content>
          <span>Are you sure you want to recover this account?</span>
          <br />
          <br />
          <div style={{ color: 'red' }}>{error}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setRecoverModalOpen(false)}>
            Cancel
          </Button>
          <Button primary onClick={handleRecovery} disabled={!canIUse(AppFeatures.EditAllAccountSettings)}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
