import React, { useEffect, useState } from 'react';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { Button, Checkbox, Dropdown, Icon, Input, Table } from 'semantic-ui-react';
import { GroupSelect } from '../shared/groupselect/GroupSelect';
import { CategorySelect } from '../shared/categoryselect/CategorySelect';
import './FeatureFlagDetails.scss';
import { apiCall } from '../../utility/api';
import { useMessages } from '../../context/MessagesContext';
import { FeatureFlag, FeatureFlagDataType, FeatureFlagPromoCodeDefault, FeatureFlagSubscriptionDefault, PromoCode } from './types';
import { FeatureFlagValue } from './FeatureFlagValue';
import { subscriptionOptions } from '../../utility/Constants';
import Loader from '../Loader';
import { getDefaultValueFromFeatureObject, setFeatureFlagValueByType } from './utilities';
import { useApi } from '../../hooks/useApi';
import ConfirmationModal from '../ConfirmModal/ConfirmationModal';

interface FeatureFlagDetailsProps {
  featureFlag: FeatureFlag;
  featureFlags: FeatureFlag[];
  onFeatureFlagSaved: (newFeatureFlag: FeatureFlag) => void;
  onFeatureFlagDeleted: () => void;
}

export function FeatureFlagDetails(props: FeatureFlagDetailsProps) {
  const [promoCodes, _promoCodesLoading, promoCodesLoaded] = useApi<PromoCode[]>(`api/promocodes`);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const prepareFeatureFlag = (ff: FeatureFlag) => {
    const setDataTypeDefault = (obj: FeatureFlag): FeatureFlag => ({ dataType: obj.dataType ?? FeatureFlagDataType.BoolValue });
    const setSubscriptionDefaults = (obj: FeatureFlag): FeatureFlag => ({ subscriptionDefaults: obj.subscriptionDefaults ?? [] });
    const setPromoCodeDefaults = (obj: FeatureFlag): FeatureFlag => ({ promoCodeDefaults: obj.promoCodeDefaults ?? [] });
    return [setDataTypeDefault, setSubscriptionDefaults, setPromoCodeDefaults].reduce((value, fn) => ({ ...value, ...fn(value) }), ff ? { ...ff } : {});
  };

  const [featureFlag, setFeatureFlag] = useState<FeatureFlag>(prepareFeatureFlag(props.featureFlag));
  const [isUpdating, setIsUpdating] = useState(false);
  const { showMessage } = useMessages();
  const readOnly = !canIUse(AppFeatures.EditFeatureFlags);

  useEffect(() => {
    setFeatureFlag(prepareFeatureFlag(props.featureFlag));
  }, [props.featureFlag]);

  const dataTypeOptions = Object.getOwnPropertyNames(FeatureFlagDataType)
    .filter((value) => Number.isNaN(Number.parseInt(value)))
    .map((key) => ({ key: FeatureFlagDataType[key], text: key.replace('Value', ''), value: FeatureFlagDataType[key] }));

  const categoryOptions = [...(props.featureFlags || []).map((ff) => ff.category), featureFlag.category].filter(
    (cat, idx, arr) => arr != null && cat != null && arr.indexOf(cat) === idx
  );

  const nonUsedSubscriptionOptions = (selfSubscription: string) =>
    subscriptionOptions.filter((so) => so.value === selfSubscription || !(featureFlag.subscriptionDefaults || []).some((def) => def.subscription === so.value));

  const promoCodeOptions = (promoCodes || []).map((pc) => ({ key: pc.id, text: `${pc.code}${pc.description ? ` (${pc.description})` : ''}`, value: pc.id }));

  const nonUsedPromoCodeOptions = (selfPromoCodeId: string) =>
    promoCodeOptions.filter((pco) => pco.value === selfPromoCodeId || !(featureFlag.promoCodeDefaults || []).some((def) => def.promoCodeId === pco.value));

  const setfeatureFlagProp = (propName: keyof FeatureFlag, value) => {
    setFeatureFlag({ ...featureFlag, ...{ [propName]: value } });
  };

  const setFeatureFlagName = (value) => {
    const update: FeatureFlag = { flagName: value };
    if (value != null && featureFlag.featureFlagId == null) {
      update.flagCodeName = value.replaceAll(' ', '');
    }

    setFeatureFlag({ ...featureFlag, ...update });
  };
  const setDefaultValueProp = (value) => setFeatureFlagValueByType(featureFlag, value, setfeatureFlagProp);

  const setSubscriptionDefaultValue = (index, prop: keyof FeatureFlagSubscriptionDefault | 'defaultValue', value) => {
    const subscriptionDefaults = [...featureFlag.subscriptionDefaults];
    const setValue = () => {
      if (prop === 'subscription') {
        subscriptionDefaults[index].subscription = value;
        return;
      }

      if (prop === 'defaultValue') {
        return setFeatureFlagValueByType(featureFlag, value, (dfProp, newValue) => {
          subscriptionDefaults[index][dfProp] = newValue;
        });
      }
    };

    setValue();
    setFeatureFlag({ ...featureFlag, subscriptionDefaults: subscriptionDefaults });
  };

  const setPromoCodeDefaultValue = (index, prop: keyof FeatureFlagPromoCodeDefault | 'defaultValue', value) => {
    const promoCodeDefaults = [...featureFlag.promoCodeDefaults];
    const setValue = () => {
      if (prop === 'promoCodeId') {
        promoCodeDefaults[index].promoCodeId = value;
        return;
      }

      if (prop === 'defaultValue') {
        return setFeatureFlagValueByType(featureFlag, value, (dfProp, newValue) => {
          promoCodeDefaults[index][dfProp] = newValue;
        });
      }
    };

    setValue();
    setFeatureFlag({ ...featureFlag, promoCodeDefaults: promoCodeDefaults });
  };

  const getDefaultValueProp = () => getDefaultValueFromFeatureObject(featureFlag, featureFlag.dataType);

  const getSubscriptionDefaultValueProp = (sd: FeatureFlagSubscriptionDefault) => getDefaultValueFromFeatureObject(sd, featureFlag.dataType);

  const getPromoCodeDefaultValueProp = (pcd: FeatureFlagPromoCodeDefault) => getDefaultValueFromFeatureObject(pcd, featureFlag.dataType);

  const validateForSave = () => {
    if (!featureFlag?.description || !featureFlag?.flagName || !featureFlag?.flagCodeName || !featureFlag?.category) {
      showMessage(`Please fill out all required fields before saving.`);
      return false;
    }

    if ((props.featureFlags || []).filter((ff) => ff.featureFlagId !== featureFlag.featureFlagId).some((ff) => ff.flagCodeName === featureFlag.flagCodeName)) {
      showMessage(`The code "${featureFlag.flagCodeName}" is already in use. Please choose a different one.`);
      return false;
    }

    if (featureFlag.dataType === FeatureFlagDataType.JsonValue) {
      try {
        JSON.parse(featureFlag.jsonValue);
      } catch (e) {
        showMessage(`Invalid JSON\n${featureFlag.jsonValue}`);
        return false;
      }
    }

    featureFlag.subscriptionDefaults = featureFlag.subscriptionDefaults.filter((sdf) => sdf?.subscription != null);
    const subDefaults = featureFlag.subscriptionDefaults.map((sd) => `${sd.subscription}`);
    const subDefaultDuplicate = subDefaults.find((sd, idx) => subDefaults.indexOf(sd) != idx);
    if (subDefaultDuplicate) {
      showMessage(`Duplicate subscription default found for ${subDefaultDuplicate}`);
      return false;
    }

    featureFlag.promoCodeDefaults = featureFlag.promoCodeDefaults.filter((pdf) => pdf?.promoCodeId != null);
    const promoCodeDefaults = featureFlag.promoCodeDefaults.map((pcd) => `${pcd.promoCodeId}`);
    const promoCodeDuplicate = promoCodeDefaults.find((pcd, idx) => promoCodeDefaults.indexOf(pcd) != idx);
    if (promoCodeDuplicate) {
      showMessage(`Duplicate promo code default found for ${promoCodeDuplicate}`);
      return false;
    }

    return true;
  };

  const onDeleteSubscriptionDefaultClick = (idx: number) => {
    const newSubscriptionDefaults = [...featureFlag.subscriptionDefaults];
    newSubscriptionDefaults.splice(idx, 1);
    setFeatureFlag({ ...featureFlag, subscriptionDefaults: newSubscriptionDefaults });
  };

  const onAddSubscriptionDefaultClick = () => {
    const existingSubscriptions = featureFlag.subscriptionDefaults ?? [];
    const defaultSub = subscriptionOptions.find((so) => !existingSubscriptions.some((es) => es.subscription === so.value))?.value;
    setFeatureFlag({ ...featureFlag, subscriptionDefaults: [...featureFlag.subscriptionDefaults, { subscription: defaultSub }] });
  };

  const onDeletePromoCodeDefaultClick = (idx: number) => {
    const newPromoCodeDefaults = [...featureFlag.promoCodeDefaults];
    newPromoCodeDefaults.splice(idx, 1);
    setFeatureFlag({ ...featureFlag, promoCodeDefaults: newPromoCodeDefaults });
  };

  const onAddPromoCodeDefaultClick = () => {
    const existingPromoCodes = featureFlag.promoCodeDefaults ?? [];
    const defaultId = promoCodeOptions.find((pc) => !existingPromoCodes.some((epc) => epc.promoCodeId === pc.value))?.value;
    setFeatureFlag({ ...featureFlag, promoCodeDefaults: [...featureFlag.promoCodeDefaults, { promoCodeId: defaultId }] });
  };

  const onDeleteClick = async () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const onDeleteConfirmClick = async () => {
    setIsConfirmDeleteModalOpen(false);
    setIsUpdating(true);

    const tryDelete = async () => {
      try {
        const result = await apiCall(`/api/featureFlags/${featureFlag.featureFlagId}`, 'DELETE');

        if (result.status !== 200) {
          showMessage('Failed to delete the feature flag');
          return;
        }
        props?.onFeatureFlagDeleted();
      } catch (e) {
        console.error(e);
        showMessage('Failed to delete the feature flag');
      }
    };

    await tryDelete();
    setIsUpdating(false);
  };

  const onSaveClick = async () => {
    if (!validateForSave()) {
      return;
    }

    setFeatureFlag({ ...featureFlag });
    setIsUpdating(true);
    const trySave = async () => {
      try {
        const result = await (featureFlag?.featureFlagId == null
          ? apiCall(`/api/featureFlags`, 'POST', { ...featureFlag })
          : apiCall(`/api/featureFlags/${featureFlag.featureFlagId}`, 'PUT', { ...featureFlag }));

        if (result.status !== 200) {
          console.log(result.data);
          showMessage('Failed to save the feature flag');
          return;
        }
        props?.onFeatureFlagSaved(result.data as FeatureFlag);
      } catch (e) {
        console.log(e);
        showMessage('Failed to save the feature flag');
      }
    };

    await trySave();
    setIsUpdating(false);
  };

  const loading = !promoCodesLoaded || isUpdating;
  return (
    <div className="feature-flag-details">
      <Loader isLoading={loading} />
      <div className="ff-body">
        <div className="ff-field-group">
          <div className="ff-field-value ff-name">
            <label>Name*</label>
            <Input
              fluid
              placeholder="Cool Feature..."
              disabled={readOnly}
              value={featureFlag.flagName ?? ''}
              onChange={(_, data) => setFeatureFlagName(data.value)}
            />
          </div>
          <div className="ff-field-value ff-code-name">
            <label>Code*</label>
            <Input
              fluid
              placeholder="Code For Code"
              disabled={readOnly}
              value={featureFlag.flagCodeName ?? ''}
              onChange={(_, data) => setfeatureFlagProp('flagCodeName', data.value)}
            />
          </div>
          <div className="ff-field-value ff-category">
            <label>Category*</label>
            <CategorySelect
              value={featureFlag.category}
              onChange={(value) => setfeatureFlagProp('category', value)}
              categories={categoryOptions}
              disabled={readOnly}
            />
          </div>
        </div>

        <div className="ff-field-value ff-description">
          <label>Description*</label>
          <Input
            fluid
            placeholder="All The Deets..."
            disabled={readOnly}
            value={featureFlag.description ?? ''}
            onChange={(_, data) => setfeatureFlagProp('description', data.value)}
          />
        </div>

        <div className="ff-field-value ff-client-controlled">
          <Checkbox
            label="Client Controlled"
            checked={featureFlag.clientControlled ?? false}
            onChange={(_, value) => setfeatureFlagProp('clientControlled', value.checked)}
            disabled={readOnly}
          ></Checkbox>
        </div>
        <div className="ff-field-value ff-default-value">
          <label>Default Value</label>
          <div>
            <Dropdown
              className="data-type"
              selection
              fluid
              search
              value={featureFlag.dataType}
              onChange={(_, data) => setfeatureFlagProp('dataType', data.value)}
              options={dataTypeOptions}
              disabled={readOnly}
            />
            <FeatureFlagValue dataType={featureFlag.dataType} value={getDefaultValueProp()} onChange={setDefaultValueProp} disabled={readOnly} />
          </div>
        </div>

        <div className="ff-field-value ff-read-groups">
          <label>Read Groups</label>
          <GroupSelect value={featureFlag.readGroupIds || ''} onChange={(value) => setfeatureFlagProp('readGroupIds', value)} disabled={readOnly} />
        </div>
        <div className="ff-field-value ff-write-groups">
          <label>Write Groups</label>
          <GroupSelect value={featureFlag.writeGroupIds || ''} onChange={(v) => setfeatureFlagProp('writeGroupIds', v)} disabled={readOnly} />
        </div>

        <div className="ff-field-value ff-subscription-defaults">
          <label>Subscription Defaults</label>
          <label className="description">The default value for a client in a subscription. This supersedes the default value of the feature flag itself</label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Subscription</Table.HeaderCell>
                <Table.HeaderCell>Default Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {featureFlag.subscriptionDefaults.map((sd, idx) => (
                <Table.Row key={`subscriptionDefaults-${idx}`}>
                  <Table.Cell collapsing>
                    <Button icon="minus circle" className="remove-row-button" disabled={readOnly} onClick={() => onDeleteSubscriptionDefaultClick(idx)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      search
                      disabled={readOnly}
                      value={sd.subscription}
                      options={nonUsedSubscriptionOptions(sd.subscription)}
                      onChange={(_, data) => setSubscriptionDefaultValue(idx, 'subscription', data.value)}
                    ></Dropdown>
                  </Table.Cell>
                  <Table.Cell>
                    <FeatureFlagValue
                      dataType={featureFlag.dataType}
                      value={getSubscriptionDefaultValueProp(sd)}
                      onChange={(value) => setSubscriptionDefaultValue(idx, 'defaultValue', value)}
                      disabled={readOnly}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  {!readOnly && (
                    <Button onClick={onAddSubscriptionDefaultClick} className="primary" floated="right">
                      <Icon name="plus circle" /> Add Subscription Default
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>

        <div className="ff-field-value ff-promo-code-defaults">
          <label>Account Creation Overrides</label>
          <label className="description">Apply a Feature Flag Override to an account on signup based on the Promo Code used</label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Promo Code</Table.HeaderCell>
                <Table.HeaderCell>Default Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {featureFlag.promoCodeDefaults.map((pcd, idx) => (
                <Table.Row key={`promoCodeDefaults-${idx}`}>
                  <Table.Cell collapsing>
                    <Button icon="minus circle" className="remove-row-button" disabled={readOnly} onClick={() => onDeletePromoCodeDefaultClick(idx)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      search
                      disabled={readOnly}
                      value={pcd.promoCodeId}
                      options={nonUsedPromoCodeOptions(pcd.promoCodeId)}
                      onChange={(_, data) => setPromoCodeDefaultValue(idx, 'promoCodeId', data.value)}
                    ></Dropdown>
                  </Table.Cell>
                  <Table.Cell>
                    <FeatureFlagValue
                      dataType={featureFlag.dataType}
                      value={getPromoCodeDefaultValueProp(pcd)}
                      onChange={(value) => setPromoCodeDefaultValue(idx, 'defaultValue', value)}
                      disabled={readOnly}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  {!readOnly && (
                    <Button onClick={onAddPromoCodeDefaultClick} className="primary" floated="right">
                      <Icon name="plus circle" /> Add Promo Code Default
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>

        <div className="form-buttons">
          <Button className="tertiary" negative onClick={onDeleteClick}>
            Delete
          </Button>
          <Button className="primary" onClick={onSaveClick}>
            Save
          </Button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isConfirmDeleteModalOpen}
        headerText={`Confirm Deletion of ${featureFlag.flagName}`}
        confirmationText={`Are you sure you would like to delete ${featureFlag.flagName}?`}
        closeModal={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={onDeleteConfirmClick}
      />
    </div>
  );
}
