import React, { useState } from 'react';
import './Subscriptions.scss';
import { Menu, MenuItem, Segment } from 'semantic-ui-react';
import { subscriptionOptions } from '../../utility/Constants';
import { SubscriptionFeatures } from './SubscriptionFeatures';
import { SubscriptionApiThrottling } from './SubscriptionApiThrottling';
import { SubscriptionAddOns } from './SubscriptionAddOns';
import { GlobalDefault } from './SubscriptionConstants';
import { UnavailableAddOnSubscription } from '../addons/AddOnConstants';

export function Subscriptions() {
  const [selectedSubscription, setSelectedSubscription] = useState('gf-trial');
  const [activeSubMenu, setActiveSubMenu] = useState('Features');

  const subscriptionOptionsWithGlobal = [GlobalDefault, ...subscriptionOptions];
  const UnavailableApiLimitSubscriptions: string[] = [GlobalDefault.value];

  const selectedSubscriptionWithGobalAsNull = selectedSubscription === GlobalDefault.value ? null : selectedSubscription;
  const subMenuMap = [
    {
      name: 'Features',
      component: <SubscriptionFeatures subscription={selectedSubscriptionWithGobalAsNull} />,
    },
    UnavailableAddOnSubscription.includes(selectedSubscription)
      ? null
      : {
          name: 'Add Ons',
          component: <SubscriptionAddOns subscription={selectedSubscriptionWithGobalAsNull} />,
        },
    UnavailableApiLimitSubscriptions.includes(selectedSubscription)
      ? null
      : {
          name: 'API Limits',
          component: <SubscriptionApiThrottling subscription={selectedSubscriptionWithGobalAsNull} />,
        },
  ].filter((comp) => !!comp);

  const handleSubscriptionSelection = (value: string) => {
    setSelectedSubscription(value);

    if (UnavailableAddOnSubscription.includes(value) && activeSubMenu === 'Add Ons') {
      setActiveSubMenu('Features');
    }

    if (UnavailableApiLimitSubscriptions.includes(value) && activeSubMenu === 'API Limits') {
      setActiveSubMenu('Features');
    }
  };

  const handleSubMenuClick = (value: string) => {
    setActiveSubMenu(value);
  };

  return (
    <div className="subscriptions">
      <div className="page-header">
        <div className="header-item">
          <h2>Subscriptions</h2>
        </div>
      </div>
      <div className="page-container">
        <div className="page-menu">
          <Menu secondary vertical>
            {subscriptionOptionsWithGlobal.map((subscription) => (
              <MenuItem
                key={subscription.key}
                name={subscription.text}
                active={selectedSubscription === subscription.value}
                onClick={() => handleSubscriptionSelection(subscription.value)}
              />
            ))}
          </Menu>
        </div>
        <div className="page-content">
          <Menu attached="top" tabular>
            {subMenuMap.map((menu) => (
              <MenuItem key={menu.name} name={menu.name} active={activeSubMenu === menu.name} onClick={() => handleSubMenuClick(menu.name)} />
            ))}
          </Menu>
          <Segment attached="bottom">{subMenuMap.find((menu) => menu.name === activeSubMenu)?.component}</Segment>
        </div>
      </div>
    </div>
  );
}
