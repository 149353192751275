export const SUBSCRIPTION_CODES = {
  TRIAL: 'gf-trial',
  BASIC: 'gf-basic',
  TEACHER: 'gf-teacher',
  TEAM: 'gf-team',
  ADVANCED: 'gf-advanced',
  ENTERPRISE: 'gf-enterprise',
};

export const subscriptionOptions = [
  {
    key: SUBSCRIPTION_CODES.TRIAL,
    text: 'Trial',
    value: SUBSCRIPTION_CODES.TRIAL,
  },
  {
    key: SUBSCRIPTION_CODES.BASIC,
    text: 'Basic',
    value: SUBSCRIPTION_CODES.BASIC,
  },
  {
    key: SUBSCRIPTION_CODES.TEAM,
    text: 'Team',
    value: SUBSCRIPTION_CODES.TEAM,
  },
  {
    key: SUBSCRIPTION_CODES.ADVANCED,
    text: 'Advanced',
    value: SUBSCRIPTION_CODES.ADVANCED,
  },
  {
    key: SUBSCRIPTION_CODES.ENTERPRISE,
    text: 'Enterprise',
    value: SUBSCRIPTION_CODES.ENTERPRISE,
  },
  {
    key: SUBSCRIPTION_CODES.TEACHER,
    text: 'Teacher',
    value: SUBSCRIPTION_CODES.TEACHER,
  },
];

export const billingPeriodOptions = [
  {
    key: '1',
    text: 'Monthly',
    value: '1',
  },
  {
    key: '12',
    text: 'Annual',
    value: '12',
  },
  {
    key: 'other',
    text: 'Other',
    value: 'other',
  },
];

export const dateFormatOptions = [
  { text: 'm/d/yyyy', value: 'M/d/yyyy', FormatDEO: 'mdy' },
  { text: 'm-d-yyyy', value: 'M-d-yyyy', FormatDEO: 'mdy' },
  { text: 'mm/dd/yyyy', value: 'MM/dd/yyyy', FormatDEO: 'mdy' },
  { text: 'mm-dd-yyyy', value: 'MM-dd-yyyy', FormatDEO: 'mdy' },
  { text: 'mmmm d, yyyy', value: 'MMMM d, yyyy', FormatDEO: 'mdy' },
  { text: 'M/d/Y', value: 'M/d/Y', FormatDEO: 'mdy' },
  { text: 'yyyy/mm/dd', value: 'yyyy/MM/dd', FormatDEO: 'ymd' },
  { text: 'yyyy-mm-dd', value: 'yyyy-MM-dd', FormatDEO: 'ymd' },
  { text: 'yyyy.mm.dd', value: 'yyyy.MM.dd', FormatDEO: 'ymd' },
  { text: 'd/m/yyyy', value: 'd/M/yyyy', FormatDEO: 'dmy' },
  { text: 'd-m-yyyy', value: 'd-M-yyyy', FormatDEO: 'dmy' },
  { text: 'd.m.yyyy', value: 'd.M.yyyy', FormatDEO: 'dmy' },
  { text: 'dd/m/yyyy', value: 'dd/M/yyyy', FormatDEO: 'dmy' },
  { text: 'dd/mm/yyyy', value: 'dd/MM/yyyy', FormatDEO: 'dmy' },
  { text: 'dd-mm-yyyy', value: 'dd-MM-yyyy', FormatDEO: 'dmy' },
  { text: 'dd.mm.yyyy', value: 'dd.MM.yyyy', FormatDEO: 'dmy' },
  { text: 'dd mmmm yyyy', value: 'dd MMMM yyyy', FormatDEO: 'dmy' },
];

export const timeFormatOptions = [
  { text: 'h:mm A', value: 'h:mm tt' },
  { text: 'hh:mm A', value: 'hh:mm tt' },
  { text: 'H:mm', value: 'H:mm' },
  { text: 'HH:mm', value: 'HH:mm' },
];

export const exportDateFormatOptions = [
  { text: 'ISO 8601 - YYYY-MM-DD', value: 'yyyy-MM-ddThh:mm:ss' },
  { text: 'EU - Asia - DD/MM/YYYY', value: 'dd/MM/yyyy hh:mm:ss tt' },
  { text: 'US - MM/DD/YYYY', value: 'MM/dd/yyyy hh:mm:ss tt' },
];
