import React, { useEffect, useState } from 'react';
import { Input, Button } from 'semantic-ui-react';
import { apiCall } from '../../utility/api';
import { useForm } from '../../hooks/useForm';
import { Module } from '../Module';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { CommentInput } from '../commentinput/CommentInput';
import { ClientDto } from './account-types';
import { SUBSCRIPTION_CODES } from '../../utility/Constants';

export type SubscriptionPricingProps = {
  id: string;
  account?: ClientDto;
  refreshAccount: () => void;
};

export function SubscriptionPricing(props: SubscriptionPricingProps) {
  const { id, account, refreshAccount } = props;
  const [saving, setSaving] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const subscriptionCodes = [SUBSCRIPTION_CODES.TEAM, SUBSCRIPTION_CODES.ADVANCED, SUBSCRIPTION_CODES.ENTERPRISE];

  const initialPricing = subscriptionCodes.reduce(
    (acc, key) => {
      acc[key] = account?.pricing?.[key] ?? null;
      acc[`${key}-year`] = account?.pricing?.[`${key}-year`] ?? null;
      return acc;
    },
    {} as Record<string, number | null>
  );

  const [pricing, setPricing] = useForm(initialPricing);

  useEffect(() => {
    setSaveDisabled(subscriptionCodes.some((key) => (pricing[key] && !pricing[`${key}-year`]) || (!pricing[key] && pricing[`${key}-year`])));
  }, [pricing]);

  const save = async () => {
    setSaving(true);
    const request = { pricing, comments };
    try {
      await apiCall(`/api/account/${id}/pricing`, 'PUT', request);
      refreshAccount();
    } catch (error) {
      console.error('Failed to save pricing:', error);
    } finally {
      setComments('');
      setSaving(false);
    }
  };

  return (
    <div className="subscription-pricing">
      <div className="modules">
        <Module title="Subscription Pricing" className="fullwidth-module">
          <table className="ui very basic collapsing celled table">
            <thead>
              <tr>
                <th>Pricing (leave blank for default)</th>
                <th>Monthly Price</th>
                <th>Annual Price (Annually)</th>
                <th>Annual Price (Monthly)</th>
              </tr>
            </thead>
            <tbody>
              {subscriptionCodes.map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <PricingInput value={pricing[key]} onChange={(value) => setPricing(key, value)} error={!pricing[key] && !!pricing[`${key}-year`]} />
                  <PricingInput
                    value={pricing[`${key}-year`]}
                    onChange={(value) => setPricing(`${key}-year`, value)}
                    error={!pricing[`${key}-year`] && !!pricing[key]}
                  />
                  <PricingInput
                    value={pricing[`${key}-year`] !== null ? pricing[`${key}-year`] / 12 : null}
                    onChange={(value) => setPricing(`${key}-year`, value !== null ? value * 12 : null)}
                    error={!pricing[`${key}-year`] && !!pricing[key]}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          {!account?.deletedDate && (
            <div className="ui form">
              <br />
              <CommentInput placeholder="Comment." value={comments} onChange={setComments} disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)} />
              <br />
              <Button className="primary" onClick={save} loading={saving} disabled={saveDisabled || !comments}>
                Save
              </Button>
            </div>
          )}
        </Module>
      </div>
    </div>
  );
}

type PricingInputProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  error: boolean;
};

function PricingInput(props: PricingInputProps) {
  const { value, onChange, error } = props;
  return (
    <td className="field">
      <Input
        type="number"
        value={value === null ? '' : value}
        onChange={(e) => onChange(e.target.value !== '' ? Number(e.target.value) : null)}
        disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
        error={error}
      />
    </td>
  );
}
