import React, { useEffect, useState } from 'react';
import { useParams, Route, NavLink, Routes, Outlet } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { copyTextToClipboard } from '../../utility/clipboard';
import './Account.scss';
import { Users } from './users/Users';
import { Details } from './details/Details';
import { Metrics } from './metrics/Metrics';
import { ChangeLog } from './ChangeLog';
import Devices from './Devices';
import PublicForms from './publicforms/PublicForms';
import AdjustCredits from './publicforms/AdjustCredits';
import PublicFormPricing from './publicforms/PublicFormPricing';
import DataSources from './DataSources';
import { Audit } from './audit/Audit';
import { SubscriptionPricing } from './SubscriptionPricing';
import { UpdateSubscription } from './subscription/UpdateSubscription';
import Templates from './templates/Templates';
import Forms from './forms/Forms';
import EmbeddedError from '../../error/EmbeddedError';
import { AccountSettings } from './AccountSettings';
import { AccountDetails } from './AccountDetails';
import FormDetails from './forms/FormDetails';
import { UserDetails } from './users/UserDetails';
import ConnectedApps from './connectedapps/ConnectedApps';
import ConnectedAppPermissions from './connectedapps/ConnectedAppPermissions';
import { UpdateTrialSubscription } from './subscription/UpdateTrialSubscription';
import { AppFeatures, canIUse } from '../../utility/SecurityHelper';
import Workflows from './workflows/Workflows';
import EmailActivity from './emailactivity/EmailActivity';
import { AccountFeature, AccountFeatures } from './features/AccountFeatures';
import { AccountControls } from './AccountControls';
import { AccountDetail as AccountDetailsType, ClientDto } from './account-types';
import { AccountAddOns } from './AccountAddOns';
import { AddOnConfigurationDto, ClientAddOnDto } from '../addons/types';
import Reports from './Reports';
import AccountLimitConfigs from './accountlimitconfigs/AccountLimitConfigs';
import {
  calculateDefaultStartDate,
  filterCreatedFormsDataByTermDate,
  getAnnualCount,
  getCurrentMonthCount,
  getFeatureByFlagCodeName,
  GetFormCreateLimit,
  useGetData,
} from './helpers';
import moment from 'moment';
import { SUBSCRIPTION_CODES } from '../../utility/Constants';

export type AccountProps = {
  id?: string;
};

export function Account(props: AccountProps) {
  const { id } = useParams();
  const [account, accountLoading, accountLoaded, refreshAccount, setAccountResult, accountError] = useApi<ClientDto>(`/api/account/${id}`);
  //Client configurations
  const [clientAddOns, clientAddOnsLoading] = useApi<ClientAddOnDto[]>(`api/addons/accounts/${id}`);
  const clientAddOnsOrDefault = clientAddOns || [];
  const [addOnConfigurations, addOnConfigurationsLoading] = useApi<AddOnConfigurationDto[]>('api/addons/configurations');
  const addOnConfigurationsOrDefault = addOnConfigurations || [];

  const [emailDomain, emailDomainLoading, emailDomainLoaded] = useApi<string>(`/api/account/${id}/emaildomain`);
  const [logoSrc, setLogoSrc] = useState(null);

  const [createdData, createdDataLoading, createdDataLoaded] = useGetData('created', id, calculateDefaultStartDate(), moment().format('YYYY-MM-DD'));
  const [accountFeatures, accountFeaturesLoading, accountFeaturesLoaded, refreshAccountFeatures] = useApi<AccountFeature[]>(`/api/account/${id}/features`);

  const subscriptionACV = !accountLoaded ? 0 : account.acv;
  const addOnACV = !accountLoaded ? 0 : account.addOnACV ?? 0;
  const totalACV = subscriptionACV + addOnACV;
  const subscribedAddons =
    clientAddOnsLoading || addOnConfigurationsLoading
      ? ''
      : clientAddOnsOrDefault
          .filter((ca) => ca.effectiveUnsubscribedDate == null && ca.deletedDate == null)
          .map((ca) => addOnConfigurationsOrDefault.find((conf) => conf.addOnConfigurationId == ca.addOnConfigurationId).code)
          .join(', ');

  useEffect(() => {
    if (emailDomainLoaded && emailDomain) {
      const logoImage = new Image();
      logoImage.onload = () => {
        setLogoSrc(logoImage.src);
      };
      logoImage.src = `https://logo.clearbit.com/${emailDomain}?size=100`;
    }
  }, [emailDomain, emailDomainLoaded]);

  if (!accountLoaded) {
    if (accountError) {
      return <EmbeddedError errorCode={accountError.response.status} />;
    }
  }

  if (accountLoading || emailDomainLoading || createdDataLoading || accountFeaturesLoading || clientAddOnsLoading || addOnConfigurationsLoading) {
    return <Loader isLoading />;
  }

  const copyClientIdToClipboard = () => {
    copyTextToClipboard(account.clientId);
  };

  const renderBannerIfPending = () => {
    if (account.pendingSubscriptionCode || (account.licensedUserCount !== account.effectiveLicensedUserCount && account.effectiveLicensedUserCount)) {
      return <div className="pending">This Account has pending changes that will take effect in the next billing cycle.</div>;
    }
  };

  const currentTermStartedAt = account?.settings?.currentTermStartedAt
    ? moment.utc(account.settings.currentTermStartedAt)
    : account?.billingPeriod === 12
      ? moment.utc().subtract(12, 'months').startOf('month')
      : moment.utc().startOf('month');
  const groupedCreatedData = filterCreatedFormsDataByTermDate(createdData, currentTermStartedAt);

  const monthlyFormCreates = getCurrentMonthCount(groupedCreatedData);
  const annualFormCreates = getAnnualCount(groupedCreatedData, currentTermStartedAt);
  const formCountDisplay = account?.billingPeriod === 12 ? annualFormCreates : monthlyFormCreates;
  const formCreateLimitFeatureFlag = accountFeaturesLoaded && getFeatureByFlagCodeName(accountFeatures, 'FormCreateLimit');
  const formCreateLimit = GetFormCreateLimit(formCreateLimitFeatureFlag);
  const formCreateLimitDisplay = account?.billingPeriod === 12 ? formCreateLimit * 12 : formCreateLimit;
  const formCreateText = account?.billingPeriod === 12 ? 'Annual Form Creates:' : 'Monthly Form Creates:';

  return (
    <div className="account">
      <Helmet>
        <title>{account.name}</title>
      </Helmet>
      <div className="account-info">
        <div className="account-logo">{logoSrc ? <img src={logoSrc} /> : <Icon name="user circle" />}</div>
        <h2 className="company-name">{account.name}</h2>
        <div className="clientId">
          {account.clientId}&nbsp;
          <button className="ui compact mini icon button" onClick={copyClientIdToClipboard}>
            <i className="copy outline icon"></i>
          </button>
        </div>
        <div>{account.database}</div>
        <div>{account.subscriptionCode}</div>
        <div>{subscribedAddons}</div>
        {renderBannerIfPending()}
        {formCreateLimit && (
          <div className="form-usage">
            <b>{formCreateText}</b>&nbsp;
            <span className={`${formCountDisplay >= formCreateLimitDisplay ? 'error' : formCountDisplay >= formCreateLimitDisplay * 0.9 && 'warning'}`}>
              {formCountDisplay}/{formCreateLimitDisplay} used ({((formCountDisplay / formCreateLimitDisplay) * 100).toFixed(2)}%)
            </span>
          </div>
        )}

        <div className="stats">
          <div className="stat">
            <i className="ui icon dollar" />
            <div className="title">Price per user</div>
            <div className="value">{account.nonUserPricing ? '--' : account.price}</div>
          </div>
          <div className="stat">
            <i className="ui icon user" />
            <div className="title">Licenses</div>
            <div className="value">{account.nonUserPricing ? '--' : account.licensedUserCount}</div>
          </div>
          <div className="stat">
            <i className="ui icon calendar alternate outline" />
            <div className="title">Billing Period</div>
            <div className="value">{account.billingPeriod}</div>
          </div>
          <div className="stat">
            <i className="ui icon money bill alternate outline" />
            <div className="title">Subscription ACV</div>
            <div className="value">{subscriptionACV}</div>
          </div>
          <div className="stat">
            <i className="ui icon money bill alternate outline" />
            <div className="title">AddOn ACV</div>
            <div className="value">{addOnACV}</div>
          </div>
          <div className="stat">
            <i className="ui icon money bill alternate outline" />
            <div className="title">Total ACV</div>
            <div className="value">{totalACV}</div>
          </div>
        </div>
      </div>
      <div className="nav-wrapper">
        <div className="account-menu">
          <NavLink className="nav-link" to={`/account/${id}`} end>
            Details
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/metrics`}>
            Metrics
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/changelog`}>
            Change Log
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/accountcontrols`}>
            Account Controls
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/accountsettings`}>
            Account Settings
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/accountdetails`}>
            Account Details
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/features`}>
            Features
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/accountlimitconfigs`}>
            Account Limit Configs
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/users`}>
            Users
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/pricing`}>
            Subscription Pricing
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/addons`}>
            Add Ons
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/forms`}>
            Forms
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/templates`}>
            Templates
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/datasources`}>
            DataSources
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/workflows`}>
            Workflows
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/reports`}>
            Reports
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/devices`}>
            Devices
          </NavLink>
          {canIUse(AppFeatures.EditFinancialAccountSettings) ? (
            <NavLink className="nav-link" to={`/account/${id}/subscription`}>
              Update Subscription
            </NavLink>
          ) : null}
          {account.subscriptionCode === SUBSCRIPTION_CODES.TRIAL || account.subscriptionCode === SUBSCRIPTION_CODES.BASIC ? (
            <NavLink className="nav-link" to={`/account/${id}/trialsubscription`}>
              Trial Subscription
            </NavLink>
          ) : null}
          <NavLink className="nav-link" to={`/account/${id}/publicforms`}>
            Public Forms
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/audit`}>
            Audit
          </NavLink>
          <a className="nav-link" target="_blank" href={`https://goformz.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?sen=001&str=${id}`}>
            Salesforce
          </a>
          <NavLink className="nav-link" to={`/account/${id}/connectedapps`}>
            Connected Apps
          </NavLink>
          <NavLink className="nav-link" to={`/account/${id}/emailactivity`}>
            Message Status
          </NavLink>
        </div>
        <div className="body-wrapper">
          <Routes>
            <Route index element={<Details {...props} id={id} account={account} />} />
            <Route path="metrics" element={<Metrics {...props} id={id} account={account} />} />
            <Route path="changelog" element={<ChangeLog {...props} id={id} account={account} />} />
            <Route
              path="accountdetails"
              element={<AccountDetails {...props} id={id} account={account as AccountDetailsType} refreshAccount={refreshAccount} />}
            />
            <Route path="accountcontrols" element={<AccountControls {...props} id={id} account={account} refreshAccount={refreshAccount} />} />
            <Route path="accountsettings" element={<AccountSettings {...props} id={id} account={account} refreshAccount={refreshAccount} />} />
            <Route path="features" element={<AccountFeatures {...props} id={id} account={account} refreshAccount={refreshAccount} />} />
            <Route
              path="accountlimitconfigs"
              element={
                <AccountLimitConfigs
                  {...props}
                  id={id}
                  account={account}
                  refreshAccount={() => {
                    refreshAccount();
                    refreshAccountFeatures();
                  }}
                />
              }
            />
            <Route path="users" element={<Users {...props} id={id} account={account} />} />
            <Route path="users/:id" element={<UserDetails {...props} id={id} />} />
            <Route path="pricing" element={<SubscriptionPricing {...props} id={id} account={account} refreshAccount={refreshAccount} />} />
            <Route path="addons" element={<AccountAddOns {...props} id={id} account={account} />} />
            <Route path="datasources" element={<DataSources {...props} id={id} account={account} />} />
            <Route path="workflows" element={<Workflows {...props} id={id} account={account} />} />
            <Route path="reports" element={<Reports {...props} clientId={id} name={account.name} />} />
            <Route path="devices" element={<Devices {...props} id={id} account={account} />} />
            <Route
              path="subscription"
              element={
                <UpdateSubscription
                  {...props}
                  id={id}
                  account={account}
                  refreshAccount={() => {
                    refreshAccount();
                    refreshAccountFeatures();
                  }}
                />
              }
            />
            <Route path="trialSubscription" element={<UpdateTrialSubscription {...props} id={id} account={account} refreshAccount={refreshAccount} />} />
            <Route path="publicforms/adjustcredits" element={<AdjustCredits {...props} id={id} account={account} />} />
            <Route path="publicforms/pricing" element={<PublicFormPricing {...props} id={id} account={account} />} />
            <Route path="publicforms" element={<PublicForms {...props} id={id} account={account} />} />
            <Route path="audit" element={<Audit {...props} id={id} account={account} />} />
            <Route path="templates" element={<Templates {...props} id={id} account={account} />} />
            <Route path="forms" element={<Forms {...props} id={id} account={account} />} />
            <Route path="forms/:id" element={<FormDetails {...props} id={id} />} />
            <Route path="connectedapps" element={<ConnectedApps {...props} id={id} account={account} />} />
            <Route path="connectedapps/:id" element={<ConnectedAppPermissions {...props} clientId={id} account={account} />} />
            <Route path="emailactivity" element={<EmailActivity {...props} id={id} account={account} />} />
          </Routes>
          <Outlet />
        </div>
      </div>
      <br />
    </div>
  );
}
